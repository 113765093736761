/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PublicRebateCodeEntity = {
    id: string;
    organizationId: string;
    code: string;
    protected: boolean;
    name: string;
    type: PublicRebateCodeEntity.type;
    valueInMills: number;
    scope: PublicRebateCodeEntity.scope;
    minimumSpendingInMills: number | null;
    maxUsages: number | null;
    maxUsagesPerCustomer: number | null;
    validFrom: string;
    validUntil: string | null;
    registeredUsersOnly: boolean;
    verifiedPhoneRequired: boolean;
    customerNote: string | null;
    internalNote: string | null;
    canBeCombined: boolean;
    archived: boolean;
    isTemplate: boolean;
    createdAt: string;
    updatedAt: string;
    organizationEligibility: boolean;
};
export namespace PublicRebateCodeEntity {
    export enum type {
        ABSOLUTE = 'ABSOLUTE',
        PERMILLAGE = 'PERMILLAGE',
    }
    export enum scope {
        ORDER = 'ORDER',
        BOOKING = 'BOOKING',
        CHEAPEST_SINGLE_BOOKING = 'CHEAPEST_SINGLE_BOOKING',
        DUPLICATE_BOOKABLE = 'DUPLICATE_BOOKABLE',
    }
}

