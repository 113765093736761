import { useQuery } from '@tanstack/react-query'
import { CourseEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

interface CourseFilters {
  instructorId?: string
  archived?: boolean
}

export default function useGetCourses(filters?: CourseFilters): {
  courses: CourseEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: courses,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getCoursesQueryKey(filters), async () => {
    return qoursesApi.course.courseControllerGetCourses(filters?.instructorId, filters?.archived)
  })

  let isEmpty = true

  if (courses === undefined) {
    return { courses: [], isLoading, isEmpty, isError, refetch, isRefetching }
  }

  if (courses.length > 0) {
    isEmpty = false
  }

  return { courses, isLoading, isEmpty, isError, refetch, isRefetching }
}

export const getCoursesQueryKey = (filters?: CourseFilters) => ['courses', filters]
