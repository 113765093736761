/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PriceRebateCalculationDto } from './PriceRebateCalculationDto';
export type OrderWithPriceDetailsEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    courseId: string | null;
    multipassProductId: string | null;
    orderStatus: OrderWithPriceDetailsEntity.orderStatus;
    organizationId: string;
    stripePaymentIntentId: string | null;
    stripeCheckoutSessionId: string | null;
    guestId: string | null;
    customerId: string | null;
    termsOfServiceId: string | null;
    revocationPolicyId: string | null;
    consentGivenAt: string;
    revocationBlocked: boolean;
    checkoutType: OrderWithPriceDetailsEntity.checkoutType;
    createdBy: OrderWithPriceDetailsEntity.createdBy;
    paymentTarget: string | null;
    stripePaymentProfileId: string;
    rebateCodeId: string;
    priceRebateCalculation: PriceRebateCalculationDto;
};
export namespace OrderWithPriceDetailsEntity {
    export enum orderStatus {
        PENDING = 'PENDING',
        CHECKOUT = 'CHECKOUT',
        WAITING_FOR_BANK_TRANSFER = 'WAITING_FOR_BANK_TRANSFER',
        FULFILLED = 'FULFILLED',
        PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
        REFUNDED = 'REFUNDED',
        FAILED = 'FAILED',
    }
    export enum checkoutType {
        FREE = 'FREE',
        STRIPE = 'STRIPE',
        PAY_LATER = 'PAY_LATER',
    }
    export enum createdBy {
        ORGANIZATION = 'ORGANIZATION',
        CUSTOMER = 'CUSTOMER',
    }
}

