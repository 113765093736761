import { useQuery } from '@tanstack/react-query'
import { CourseEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCoursePublic(courseId: string): {
  course: CourseEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: course,
    isLoading,
    isError,
  } = useQuery(['public', 'course', courseId], async () => {
    return qoursesApi.coursePublic.coursePublicControllerGetCourse(courseId)
  })

  if (course === undefined) {
    return { course: {} as CourseEntity, isLoading, isError }
  }

  return { course, isLoading, isError }
}
