/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RebateCodeUsageInfoDto } from './RebateCodeUsageInfoDto';
export type PublicRebateCodeWithUsageInfoEntity = {
    id: string;
    organizationId: string;
    code: string;
    protected: boolean;
    name: string;
    type: PublicRebateCodeWithUsageInfoEntity.type;
    valueInMills: number;
    scope: PublicRebateCodeWithUsageInfoEntity.scope;
    minimumSpendingInMills: number | null;
    maxUsages: number | null;
    maxUsagesPerCustomer: number | null;
    validFrom: string;
    validUntil: string | null;
    registeredUsersOnly: boolean;
    verifiedPhoneRequired: boolean;
    customerNote: string | null;
    internalNote: string | null;
    canBeCombined: boolean;
    archived: boolean;
    isTemplate: boolean;
    createdAt: string;
    updatedAt: string;
    organizationEligibility: boolean;
    /**
     * The usage information of the rebate code. If the rebate code has no max usages set, it will be null
     */
    usageInfo: RebateCodeUsageInfoDto | null;
};
export namespace PublicRebateCodeWithUsageInfoEntity {
    export enum type {
        ABSOLUTE = 'ABSOLUTE',
        PERMILLAGE = 'PERMILLAGE',
    }
    export enum scope {
        ORDER = 'ORDER',
        BOOKING = 'BOOKING',
        CHEAPEST_SINGLE_BOOKING = 'CHEAPEST_SINGLE_BOOKING',
        DUPLICATE_BOOKABLE = 'DUPLICATE_BOOKABLE',
    }
}

