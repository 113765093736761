import { useQuery } from '@tanstack/react-query'
import { PublicBookingEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetEligibleOrderBookingsForRebateCode(
  orderId: string,
  code: string | null,
  codeId: string | null,
): {
  bookings: PublicBookingEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: bookings,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getEligibleOrderBookingsForRebateCodeQueryKey(orderId, code), async () => {
    return qoursesApi.paymentPublic.paymentPublicControllerGetEligibleOrderBookingsForRebateCode(
      orderId,
      {
        code: code && code.length > 0 ? code : undefined,
        codeId: codeId ?? undefined,
      },
    )
  })

  let isEmpty = true

  if (bookings === undefined) {
    return { bookings: [], isLoading, isEmpty, isError, refetch, isRefetching }
  }

  if (bookings.length > 0) {
    isEmpty = false
  }

  return { bookings, isLoading, isEmpty, isError, refetch, isRefetching }
}

export const getEligibleOrderBookingsForRebateCodeQueryKey = (orderId: string, code: string) => [
  'public',
  'rebate',
  'eligibilityForOrder',
  orderId,
  code,
]
