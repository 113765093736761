import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { OrganizationEntity } from '../../qourses-api-client'

export const organizationStore = create<
  OrganizationEntity & {
    storageLastUpdated: Date
    updateActiveOrganization: (organization: OrganizationEntity) => void
  }
>()(
  persist(
    (set) => ({
      id: '',
      name: '',
      companyLegalName: '',
      description: '',
      slug: '',
      createdAt: '',
      updatedAt: '',
      activeCustomer: null,
      storageLastUpdated: new Date(),
      highlightedTag1Id: '',
      highlightedTag2Id: '',
      highlightedTag3Id: '',
      testMode: false,
      archived: false,
      updateActiveOrganization: (organization: OrganizationEntity) =>
        set({
          //check if the new active tenant is empty
          id: organization.id,
          name: organization.name,
          companyLegalName: organization.companyLegalName,
          slug: organization.slug,
          description: organization.description,
          createdAt: organization.createdAt,
          updatedAt: organization.updatedAt,
          activeCustomer: organization.activeCustomer,
          storageLastUpdated: new Date(),
          highlightedTag1Id: organization.highlightedTag1Id,
          highlightedTag2Id: organization.highlightedTag2Id,
          highlightedTag3Id: organization.highlightedTag3Id,
          testMode: organization.testMode,
          archived: organization.archived,
        }),
    }),
    {
      name: 'organization-storage',
    },
  ),
)
