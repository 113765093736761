import { CourseSelection } from '@/components/entityDropdowns/CourseSelection.tsx'
import { MultipassProductSelection } from '@/components/entityDropdowns/MultipassProductSelection.tsx'
import { TagSelection } from '@/components/entityDropdowns/TagSelection.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useOrganization from '@/hooks/organization/useOrganization.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Checkbox } from '@/shadcn/components/ui/checkbox.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shadcn/components/ui/tabs.tsx'
import {
  ToastVariant,
  copyBookingLinkToClipboard,
  createBookingLink,
  sendNotification,
} from '@/utils.tsx'
import { Calendar, Copy, Loader2, Tag, Ticket } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CourseEntity, MultipassProductEntity, TagEntity } from '../../../qourses-api-client'

export function CreateBookingLinkModal() {
  const { t: translate } = useTranslation()
  const { organization } = useOrganization()

  const [selectedCourse, setSelectedCourse] = useState<CourseEntity>(undefined)
  // const [selectedBookable, setSelectedBookable] =
  //   useState<IdentifiableBookableBackoffice>(undefined)
  const [selectedMultipassProduct, setSelectedMultipassProduct] =
    useState<MultipassProductEntity>(undefined)
  const [selectedTag, setSelectedTag] = useState<TagEntity>(undefined)
  const [submitting] = useState(false)

  const [includeDetails, setIncludeDetails] = useState(true)
  const [includeLocation, setIncludeLocation] = useState(true)
  const [includeInstructor, setIncludeInstructor] = useState(true)

  // organization.id,
  //   selectedCourse ? selectedCourse.id : undefined,
  //   '',
  //   selectedTag ? selectedTag.id : undefined,
  //   selectedMultipassProduct ? selectedMultipassProduct.id : undefined,
  //   includeDetails,
  //   includeLocation,
  //   includeInstructor,

  const handleCreateBookingLink = () => {
    copyBookingLinkToClipboard({
      organizationId: organization.id,
      courseId: selectedCourse ? selectedCourse.id : undefined,
      tagId: selectedTag ? selectedTag.id : undefined,
      multipassProductId: selectedMultipassProduct ? selectedMultipassProduct.id : undefined,
      includeDetails,
      includeLocation,
      includeInstructor,
    })
    popAllModals()
    sendNotification(
      translate('modals.createBookingLinkModal.notification.title'),
      translate('modals.createBookingLinkModal.notification.subtitle'),
      ToastVariant.Success,
    )
  }

  const handlePreviewBookingLink = () => {
    createBookingLink({
      organizationId: organization.id,
      courseId: selectedCourse ? selectedCourse.id : undefined,
      tagId: selectedTag ? selectedTag.id : undefined,
      multipassProductId: selectedMultipassProduct ? selectedMultipassProduct.id : undefined,
      includeDetails,
      includeLocation,
      includeInstructor,
      newTab: true,
    })
    popAllModals()
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <div className="h-[80vh] overflow-y-auto p-2 sm:h-auto sm:overflow-auto sm:p-0">
        <DialogHeader className="mb-2">
          <DialogTitle className="mt-6 sm:mt-2">
            {translate('modals.createBookingLinkModal.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.createBookingLinkModal.subtitle')}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col justify-end gap-y-4">
          <Tabs defaultValue={'courses'} className="mt-4 w-full">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="courses" className="truncate">
                <Calendar className="mr-1 size-4" />
                {translate('modals.createBookingLinkModal.tabs.courses')}
              </TabsTrigger>
              <TabsTrigger value="tags" className="truncate">
                <Tag className="mr-1 size-4" />
                {translate('modals.createBookingLinkModal.tabs.tags')}
              </TabsTrigger>
              <TabsTrigger value="multipassProducts">
                <Ticket className="mr-1 size-4" />
                {translate('modals.createBookingLinkModal.tabs.multipassProducts')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value={'courses'}>
              <div className="p-2">
                <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
                  <Calendar className="mr-1 size-3" />
                  {translate('modals.createBookingLinkModal.course.label')}
                </label>
                <CourseSelection
                  selectedCourse={selectedCourse}
                  setSelectedCourse={setSelectedCourse}
                  disabled={false}
                />
                <p className="mt-1 text-sm text-muted-foreground">
                  {translate('modals.createBookingLinkModal.course.subtitle')}
                </p>
              </div>
            </TabsContent>
            <TabsContent value={'tags'}>
              <div className="p-2">
                <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
                  <Tag className="mr-1 size-3" />
                  {translate('modals.createBookingLinkModal.tag.label')}
                </label>
                <TagSelection
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                  disabled={false}
                />
                <p className="mt-1 text-sm text-muted-foreground">
                  {translate('modals.createBookingLinkModal.tag.subtitle')}
                </p>
              </div>
            </TabsContent>
            <TabsContent value={'multipassProducts'}>
              <div className="p-2">
                <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
                  <Ticket className="mr-1 size-3" />
                  {translate('modals.createBookingLinkModal.multipass.label')}
                </label>
                <MultipassProductSelection
                  selectedMultipassProduct={selectedMultipassProduct}
                  setSelectedMultipassProduct={setSelectedMultipassProduct}
                  disabled={false}
                />
                <p className="mt-1 text-sm text-muted-foreground">
                  {translate('modals.createBookingLinkModal.multipass.subtitle')}
                </p>
              </div>
            </TabsContent>
          </Tabs>

          {/*{selectedCourse && (*/}
          {/*  <div className="p-2">*/}
          {/*    <label className="mb-2 text-sm font-medium text-gray-700 flex items-center">*/}
          {/*      <SquareStack className="size-3 mr-1" />*/}
          {/*      {translate('modals.createBookingLinkModal.bookable.label')}*/}
          {/*    </label>*/}
          {/*    <CourseBookableSelection*/}
          {/*      courseId={selectedCourse.id}*/}
          {/*      selectedBookable={selectedBookable}*/}
          {/*      setSelectedBookable={setSelectedBookable}*/}
          {/*      disabled={!selectedCourse}*/}
          {/*    />*/}
          {/*    <p className="mt-1 text-sm text-muted-foreground">*/}
          {/*      {translate('modals.createBookingLinkModal.bookable.subtitle')}*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="gap-y-4">
            <div className="my-2 mb-4 flex justify-start gap-x-2 text-xs">
              <Checkbox
                id="includeDetails"
                checked={includeDetails}
                onClick={() => setIncludeDetails(!includeDetails)}
              />
              <label htmlFor="includeDetails" className="text-sm font-medium leading-none">
                {translate('modals.createBookingLinkModal.includeDetails.label')}
                <p className="mt-1 text-xs text-gray-600">
                  {translate('modals.createBookingLinkModal.includeDetails.subtitle')}
                </p>
              </label>
            </div>
            <div className="my-2 mb-4 flex justify-start gap-x-2 text-xs">
              <Checkbox
                id="includeLocation"
                checked={includeLocation}
                onClick={() => setIncludeLocation(!includeLocation)}
              />
              <label htmlFor="includeLocation" className="text-sm font-medium leading-none">
                {translate('modals.createBookingLinkModal.includeLocation.label')}
                <p className="mt-1 text-xs text-gray-600">
                  {translate('modals.createBookingLinkModal.includeLocation.subtitle')}
                </p>
              </label>
            </div>
            <div className="my-2 mb-4 flex justify-start gap-x-2 text-xs">
              <Checkbox
                id="includeInstructor"
                checked={includeInstructor}
                onClick={() => setIncludeInstructor(!includeInstructor)}
              />
              <label htmlFor="includeInstructor" className="text-sm font-medium leading-none">
                {translate('modals.createBookingLinkModal.includeInstructor.label')}
                <p className="mt-1 text-xs text-gray-600">
                  {translate('modals.createBookingLinkModal.includeInstructor.subtitle')}
                </p>
              </label>
            </div>
          </div>
          <div className="flex flex-1">
            <Button
              variant="indigo"
              className="mr-2 mt-8 flex flex-1"
              onClick={handleCreateBookingLink}
            >
              {submitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {translate('common.loading')}
                </>
              ) : (
                <>
                  <Copy className="mr-2 size-4" />
                  {translate('modals.createBookingLinkModal.button')}
                </>
              )}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 mt-8 flex flex-1"
              onClick={handlePreviewBookingLink}
            >
              <Copy className="mr-2 size-4" />
              {translate('modals.createBookingLinkModal.previewButton')}
            </Button>
          </div>
        </div>
      </div>
    </Dynamic.Content>
  )
}
