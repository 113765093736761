/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstructorEntity } from '../models/InstructorEntity';
import type { UpdateInstructorTestModeDto } from '../models/UpdateInstructorTestModeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstructorAdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Update an Instructor Test Mode
     * @param instructorId
     * @param requestBody
     * @returns InstructorEntity Instructor has been updated
     * @throws ApiError
     */
    public instructorAdminControllerUpdateInstructorTestMode(
        instructorId: string,
        requestBody: UpdateInstructorTestModeDto,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/instructor/{instructorId}',
            path: {
                'instructorId': instructorId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
