/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingCancellationRefundEligibilityDto } from '../models/BookingCancellationRefundEligibilityDto';
import type { BookingEntity } from '../models/BookingEntity';
import type { BookingWithStripeLineItemEntity } from '../models/BookingWithStripeLineItemEntity';
import type { FormSubmissionEntity } from '../models/FormSubmissionEntity';
import type { OrderEntity } from '../models/OrderEntity';
import type { PatchBackofficeBookingDto } from '../models/PatchBackofficeBookingDto';
import type { PatchPaymentProfileDto } from '../models/PatchPaymentProfileDto';
import type { PostCancellationDto } from '../models/PostCancellationDto';
import type { PostOrderWithBookingsDto } from '../models/PostOrderWithBookingsDto';
import type { PostRefundDto } from '../models/PostRefundDto';
import type { PostReplaceBookableDto } from '../models/PostReplaceBookableDto';
import type { StripeOnboardingObjectDto } from '../models/StripeOnboardingObjectDto';
import type { StripePaymentProfileEntity } from '../models/StripePaymentProfileEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all StripePaymentProfiles the Organization has access to (Organization & Instructors)
     * @returns StripePaymentProfileEntity The StripePaymentProfile have been returned
     * @throws ApiError
     */
    public paymentControllerGetAllOrganizationStripePaymentProfiles(): CancelablePromise<Array<StripePaymentProfileEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/stripePaymentProfile',
        });
    }
    /**
     * Get the tenant StripePaymentProfile
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns StripePaymentProfileEntity The StripePaymentProfile has been returned
     * @throws ApiError
     */
    public paymentControllerGetTenantStripePaymentProfile(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<StripePaymentProfileEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/stripePaymentProfile/tenant',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create the tenant StripePaymentProfile
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns StripePaymentProfileEntity The StripePaymentProfile has been created
     * @throws ApiError
     */
    public paymentControllerCreateStripePaymentProfile(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<StripePaymentProfileEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/stripePaymentProfile/tenant',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `The StripePaymentProfile has not been found`,
            },
        });
    }
    /**
     * Change the tenant StripePaymentProfile
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns StripePaymentProfileEntity The StripePaymentProfile has been changed
     * @throws ApiError
     */
    public paymentControllerPatchStripePaymentProfile(
        requestBody: PatchPaymentProfileDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<StripePaymentProfileEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/payment/stripePaymentProfile/tenant',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The StripePaymentProfile has not been found`,
            },
        });
    }
    /**
     * Get a StripePaymentProfile
     * @param stripePaymentProfileId
     * @returns StripePaymentProfileEntity The StripePaymentProfile has been returned
     * @throws ApiError
     */
    public paymentControllerGetOrganizationStripePaymentProfileById(
        stripePaymentProfileId: string,
    ): CancelablePromise<StripePaymentProfileEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/stripePaymentProfile/{stripePaymentProfileId}',
            path: {
                'stripePaymentProfileId': stripePaymentProfileId,
            },
        });
    }
    /**
     * Get a StripeOnboardingObjectDto. Permissions are WRITE, because the link has privilege to see private data and change it.
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns StripeOnboardingObjectDto The StripeOnboardingObjectDto has been returned
     * @throws ApiError
     */
    public paymentControllerGetStripePaymentProfileOnboarding(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<StripeOnboardingObjectDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/stripePaymentProfile/onboarding',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `The StripePaymentProfile has not been found. The StripeOnboardingObjectDto can only be created if a StripePaymentProfile exists.`,
            },
        });
    }
    /**
     * Get Orders
     * @param createdBy
     * @param unfulfilled
     * @returns OrderEntity The Orders have been returned
     * @throws ApiError
     */
    public paymentControllerGetOrders(
        createdBy?: 'ORGANIZATION' | 'CUSTOMER',
        unfulfilled?: boolean,
    ): CancelablePromise<Array<OrderEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/order',
            query: {
                'createdBy': createdBy,
                'unfulfilled': unfulfilled,
            },
        });
    }
    /**
     * Create an Order for a Customer/Guest
     * @param requestBody
     * @returns OrderEntity The Order has been returned
     * @throws ApiError
     */
    public paymentControllerCreateOrderWithBookingsForCustomer(
        requestBody: PostOrderWithBookingsDto,
    ): CancelablePromise<OrderEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get an Order
     * @param orderId
     * @returns OrderEntity The Order has been returned
     * @throws ApiError
     */
    public paymentControllerGetOrder(
        orderId: string,
    ): CancelablePromise<OrderEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/order/{orderId}',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Get Bookings of an Order
     * @param orderId
     * @returns BookingWithStripeLineItemEntity The Bookings have been returned
     * @throws ApiError
     */
    public paymentControllerGetOrderBookings(
        orderId: string,
    ): CancelablePromise<Array<BookingWithStripeLineItemEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/order/{orderId}/bookings',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Get Bookings of an Order including the stripe line items
     * @param orderId
     * @returns BookingWithStripeLineItemEntity The Bookings with line items have been returned
     * @throws ApiError
     */
    public paymentControllerGetOrderBookingsWithLineItems(
        orderId: string,
    ): CancelablePromise<Array<BookingWithStripeLineItemEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/order/{orderId}/bookingsWithLineItems',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Get Form Submissions of an Order
     * @param orderId
     * @returns FormSubmissionEntity The Form Submissions have been returned
     * @throws ApiError
     */
    public paymentControllerGetOrderFormSubmissions(
        orderId: string,
    ): CancelablePromise<Array<FormSubmissionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/order/{orderId}/formSubmissions',
            path: {
                'orderId': orderId,
            },
        });
    }
    /**
     * Get Bookings
     * @returns BookingEntity The Bookings have been returned
     * @throws ApiError
     */
    public paymentControllerGetBookings(): CancelablePromise<Array<BookingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/booking',
        });
    }
    /**
     * Get a Booking
     * @param bookingId
     * @returns BookingEntity The Booking has been returned
     * @throws ApiError
     */
    public paymentControllerGetBooking(
        bookingId: string,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/booking/{bookingId}',
            path: {
                'bookingId': bookingId,
            },
        });
    }
    /**
     * Change a Booking created by the Organization
     * @param bookingId
     * @param requestBody
     * @returns BookingEntity The Booking has been returned
     * @throws ApiError
     */
    public paymentControllerPatchOrganizationalBooking(
        bookingId: string,
        requestBody: PatchBackofficeBookingDto,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/payment/booking/{bookingId}',
            path: {
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Get cancellation refund eligibility of a Booking
     * @param bookingId
     * @param overrideTos
     * @returns BookingCancellationRefundEligibilityDto The cancellation refund eligibility has been returned
     * @throws ApiError
     */
    public paymentControllerGetBookingCancellationRefundEligibility(
        bookingId: string,
        overrideTos: boolean,
    ): CancelablePromise<BookingCancellationRefundEligibilityDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/booking/{bookingId}/cancel',
            path: {
                'bookingId': bookingId,
            },
            query: {
                'overrideTos': overrideTos,
            },
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Cancel a Booking and trigger a refund if eligible
     * @param bookingId
     * @param forceRefund
     * @param requestBody
     * @returns BookingEntity The Booking has been returned
     * @throws ApiError
     */
    public paymentControllerCancelBookingAndRefundIfEligible(
        bookingId: string,
        forceRefund: boolean,
        requestBody: PostCancellationDto,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/booking/{bookingId}/cancel',
            path: {
                'bookingId': bookingId,
            },
            query: {
                'forceRefund': forceRefund,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Withdraw a Booking created by the Organization
     * @param bookingId
     * @returns BookingEntity The Booking has been returned
     * @throws ApiError
     */
    public paymentControllerWithdrawOrganizationalBooking(
        bookingId: string,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/booking/{bookingId}/withdraw',
            path: {
                'bookingId': bookingId,
            },
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Refund a Booking
     * @param bookingId
     * @param requestBody
     * @returns BookingEntity The Booking has been returned
     * @throws ApiError
     */
    public paymentControllerRefundBooking(
        bookingId: string,
        requestBody: PostRefundDto,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/booking/{bookingId}/refund',
            path: {
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Replace the bookable of a Booking
     * @param bookingId
     * @param requestBody
     * @returns BookingEntity The Bookable has been replaced
     * @throws ApiError
     */
    public paymentControllerReplaceBookableOfBooking(
        bookingId: string,
        requestBody: PostReplaceBookableDto,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/booking/{bookingId}/replace',
            path: {
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
}
