import { useQuery } from '@tanstack/react-query'
import { MultipassProductWithCourseIdsEntityDto } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

interface MultipassProductFilters {
  eligibleCourseId?: string
  archived?: boolean
}

export default function useGetMultipassProducts(filters: MultipassProductFilters): {
  multipassProducts: MultipassProductWithCourseIdsEntityDto[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: multipassProducts,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getMultipassProductsQueryKey(filters), async () => {
    return qoursesApi.multipass.multipassControllerGetMultipassProducts(
      filters?.archived,
      filters?.eligibleCourseId,
    )
  })

  let isEmpty = true

  if (multipassProducts === undefined) {
    return { multipassProducts: [], isLoading, isEmpty, isError, refetch, isRefetching }
  }

  if (multipassProducts.length > 0) {
    isEmpty = false
  }

  return { multipassProducts, isLoading, isEmpty, isError, refetch, isRefetching }
}

export const getMultipassProductsQueryKey = (filters?: MultipassProductFilters) => [
  'multipassProducts',
  filters,
]
