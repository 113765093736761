/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ScheduleBatchEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    courseScheduleId: string;
    status: ScheduleBatchEntity.status;
    startDate: string;
    endDate: string;
};
export namespace ScheduleBatchEntity {
    export enum status {
        DRAFT = 'DRAFT',
        WAITING = 'WAITING',
        AUTOMATICALLY_APPROVED = 'AUTOMATICALLY_APPROVED',
        MANUALLY_APPROVED = 'MANUALLY_APPROVED',
        AUTOMATICALLY_REJECTED = 'AUTOMATICALLY_REJECTED',
        MANUALLY_REJECTED = 'MANUALLY_REJECTED',
    }
}

