import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { MeetingEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourseGroupMeetings(courseGroupId: string): {
  meetings: MeetingEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: meetings,
    isLoading,
    refetch,
    isError,
  } = useQuery(['courseGroups', 'meetings', courseGroupId], async () => {
    if (courseGroupId === undefined) {
      return
    }
    return qoursesApi.courseGroup.courseGroupControllerGetCourseGroupMeetings(courseGroupId)
  })

  const _isEmpty = isEmpty(meetings)

  if (meetings === undefined) {
    return { meetings: [], isLoading, isEmpty: _isEmpty, isError, refetch }
  }

  return { meetings, isLoading, isEmpty: _isEmpty, isError, refetch }
}
