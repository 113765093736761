import useGetCouponPublic from '@/hooks/public/useGetCouponPublic.tsx'
import { motion } from 'framer-motion'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface RebateCodeDisplayProps {
  rebateCodeId: string
  rebateCodeExhausted?: boolean
  cutOutColor?: string
}

const RebateCodeDisplay: React.FC<RebateCodeDisplayProps> = ({
  rebateCodeId,
  rebateCodeExhausted = false,
  cutOutColor = 'bg-white',
}) => {
  const { t: translate } = useTranslation()

  const { coupon, isLoading, isError } = useGetCouponPublic(rebateCodeId)

  if (isLoading || isError) {
    return null
  }

  return (
    <motion.div
      className="py-2 max-w-xs"
      initial={{ opacity: 0, translateY: 10 }}
      animate={{ opacity: rebateCodeExhausted ? 0.5 : 1, translateY: 0, rotate: -2 }}
      whileHover={{ rotate: 2, scale: 1.02 }}
      transition={{
        type: 'spring',
        bounce: 0.5,
      }}
    >
      <div className="relative">
        <div
          className={
            'absolute w-10 h-10 border-indigo-900/20 z-30 -top-10 right-[45%] ' + cutOutColor
          }
        />
        <div
          className={
            'absolute w-10 h-10 border-indigo-900/20 z-30 -bottom-10 right-[45%] ' + cutOutColor
          }
        />
        <div
          className={
            'absolute w-10 h-10 border-b border-indigo-900/20 z-30 -top-5 -right-5 rotate-45 ' +
            cutOutColor
          }
        />

        <div className="overflow-hidden bg-indigo-50 block bg-gradient-to-b relative from-indigo-50 to-indigo-200/30 ring-1 ring-indigo-900/20 rounded-md mt-4 p-4">
          <div className="absolute w-10 h-10 z-20 -top-3 -right-3 bg-indigo-200 bg-gradient-to-b from-indigo-200 shadow-md to-indigo-100 ring-1 rounded-md ring-indigo-900/20" />
          <div
            className={
              'absolute w-10 h-10 z-20 -top-6 right-[45%] rounded-full to-indigo-100 ring-1 ring-indigo-900/20 ' +
              cutOutColor
            }
          />
          <div
            className={
              'absolute w-10 h-10 z-20 -bottom-6 right-[45%] rounded-full to-indigo-100 ring-1 ring-indigo-900/20 ' +
              cutOutColor
            }
          />

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="mt-2 relative z-10 font-semibold text-indigo-950"
          >
            {coupon.name}
          </motion.p>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="relative z-20 text-sm text-gray-700 text-wrap my-1 italic"
          >
            {coupon.customerNote}
            <div className="absolute -left-1 -top-1 -z-10 opacity-20">
              <svg
                className="mb-2 size-6 text-gray-400 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 14"
              >
                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
              </svg>
            </div>
          </motion.div>

          <img
            src="src/assets/grungy_stamp.svg"
            className="opacity-10 absolute -bottom-16 -right-10 size-44 -rotate-[20deg] stroke-indigo-900 stroke-0 pointer-events-none"
            alt=""
          />

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="mt-2 mb-2 border-t-4 pt-2 border-gray-300 border-dotted"
          >
            {coupon.type === 'PERMILLAGE' ? (
              <div className="flex gap-x-1">
                <div className="font-semibold text-green-900 rounded-lg text-sm">
                  {translate(
                    'modals.inputVoucherCodeModal.rebateAmountWithScope.' +
                      (coupon.scope === 'ORDER' ? 'order' : 'booking'),
                    {
                      amount: coupon.valueInMills / 10 + '%',
                    },
                  )}
                </div>
              </div>
            ) : (
              <div className="flex gap-x-1 flex-col">
                <div className="flex">
                  <div className="font-semibold text-green-900 rounded-lg text-sm">
                    {translate(
                      'modals.inputVoucherCodeModal.rebateAmountWithScope.' +
                        (coupon.scope === 'ORDER' ? 'order' : 'booking'),
                      {
                        amount: coupon.valueInMills / 1000 + '€',
                      },
                    )}
                  </div>
                </div>
              </div>
            )}
            {coupon.validFrom && coupon.validUntil ? (
              <p className="text-xs text-muted-foreground font-semibold">
                {translate('modals.inputVoucherCodeModal.rebateCodeValidity.validFromAndUntil', {
                  from: DateTime.fromISO(coupon.validFrom).toLocaleString(DateTime.DATE_SHORT),
                  until: DateTime.fromISO(coupon.validUntil).toLocaleString(DateTime.DATE_SHORT),
                })}
              </p>
            ) : (
              <p className="text-xs text-muted-foreground font-semibold">
                {translate('modals.inputVoucherCodeModal.rebateCodeValidity.validFrom', {
                  date: DateTime.fromISO(coupon.validFrom).toLocaleString(DateTime.DATE_SHORT),
                })}
              </p>
            )}

            {!coupon.registeredUsersOnly &&
              coupon.usageInfo &&
              coupon.usageInfo.usagesPerCustomerLeft &&
              coupon.usageInfo.usagesLeft - coupon.usageInfo.usagesPerCustomerLeft > -1 && (
                <p className="text-xs text-muted-foreground">
                  {translate('modals.inputVoucherCodeModal.perCustomerUsageHint', {
                    amount: coupon.usageInfo.usagesPerCustomerLeft,
                  })}
                </p>
              )}

            <p className="text-xs text-muted-foreground italic mt-1">
              {translate(
                'modals.inputVoucherCodeModal.rebateScopeHint.' + coupon.scope.toLowerCase(),
              )}
            </p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

export default RebateCodeDisplay
