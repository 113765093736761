import { useQuery } from '@tanstack/react-query'
import { StripePaymentProfileEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetPaymentProfile(paymentProfileId: string): {
  paymentProfile: StripePaymentProfileEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: paymentProfile,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['paymentProfile', paymentProfileId], async () => {
    return qoursesApi.payment.paymentControllerGetOrganizationStripePaymentProfileById(
      paymentProfileId,
    )
  })

  return { paymentProfile, isLoading, isError, refetch, isRefetching }
}
