import { QoursesClient } from '../../qourses-api-client'

const apiUrl = import.meta.env.VITE_API_URL

export const qoursesApiInstructor = new QoursesClient({
  BASE: apiUrl,
  HEADERS: {
    'Content-Type': 'application/json',
    'x-instructor-indicator': 'true',
  },
})

export function setTokenInstructor(token: string) {
  qoursesApiInstructor.request.config.HEADERS = {
    ...qoursesApiInstructor.request.config.HEADERS,
    Authorization: 'Bearer ' + token,
  }
}
