import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/shadcn/components/ui/breadcrumb.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { ChevronRight } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'

interface PageHeaderProps {
  title: string
  subtitle?: string
  buttonHref?: string
  buttonTitle?: string
  titleComponent?: React.ReactNode
  buttonOnClick?: () => void
  customButton?: () => React.ReactNode
  loading?: boolean
  icon?: React.ReactNode
  breadcrumbOverride?: (path: string) => string | undefined
}

export default function PageHeader({
  title,
  subtitle,
  buttonHref,
  buttonTitle,
  titleComponent,
  buttonOnClick,
  customButton,
  loading,
  icon,
  breadcrumbOverride,
}: PageHeaderProps) {
  document.title = 'Qourses | ' + title

  const defaultBreadcrumbs = useReactRouterBreadcrumbs()
  const breadcrumbsWithoutHome = defaultBreadcrumbs.filter((breadcrumb) => breadcrumb.key !== '/')

  const { t: translate } = useTranslation()

  const renderBreadcrumbs = () => {
    return breadcrumbsWithoutHome.map((breadcrumb, index) => {
      const isLast = index === breadcrumbsWithoutHome.length - 1
      const path = breadcrumb.key

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let label = breadcrumb.breadcrumb.props.children

      // Check if there's an override for this specific path
      if (breadcrumbOverride) {
        const overrideLabel = breadcrumbOverride(path)
        if (overrideLabel) {
          label = overrideLabel
        }
      }

      // Use subtitle for the last breadcrumb if present and no override
      if (isLast && subtitle && !breadcrumbOverride?.(path)) {
        label = subtitle
      } else if (typeof label === 'string') {
        if (label.includes(' ')) {
          // If it's a dynamic segment (contains spaces), use the original label
          label = label.trim()
        } else {
          // Otherwise, use the translation
          label = translate(`breadcrumbs.${label}`)
        }
      }

      return (
        <BreadcrumbItem key={path}>
          <BreadcrumbLink asChild>
            <Link to={path}>{label}</Link>
          </BreadcrumbLink>
          {!isLast && (
            <BreadcrumbSeparator>
              <ChevronRight />
            </BreadcrumbSeparator>
          )}
        </BreadcrumbItem>
      )
    })
  }

  return (
    <div className="mb-5 sm:mb-10">
      <div>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbSeparator>
              <ChevronRight />
            </BreadcrumbSeparator>
            {renderBreadcrumbs()}
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <div className="mt-4 sm:mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 items-center sm:flex">
          {loading ? (
            <div className="w-[25%]">
              <Skeleton className="h-8" />
            </div>
          ) : (
            <>
              <div className="flex flex-1 items-center justify-between">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                  {title}
                  {subtitle && (
                    <p className="text-sm font-normal text-muted-foreground">{subtitle}</p>
                  )}
                </h2>
                {customButton && <div>{customButton()}</div>}
              </div>
              {titleComponent && (
                <div className="mt-4 flex-grow-0 sm:ml-4 sm:mt-0">{titleComponent}</div>
              )}
            </>
          )}
        </div>
        {buttonHref && buttonTitle && (
          <Button
            asChild
            className="bezel mt-2 rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-indigo-600 hover:bg-gradient-to-t focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-0"
          >
            <Link to={buttonHref}>
              {icon} {buttonTitle}
            </Link>
          </Button>
        )}
        {buttonOnClick && (
          <Button variant="indigo" type="button" onClick={buttonOnClick}>
            {buttonTitle}
          </Button>
        )}
      </div>
    </div>
  )
}
