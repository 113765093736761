import { useQuery } from '@tanstack/react-query'
import { type BookingCancellationRefundEligibilityDto } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCustomerBookingRefundOptions(bookingId: string): {
  bookingCancellationOptions: BookingCancellationRefundEligibilityDto
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: bookingCancellationOptions,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['booking', 'customer', bookingId, 'refundOptions'], async () => {
    return qoursesApi.paymentCustomer.paymentCustomerControllerGetBookingCancellationRefundEligibility(
      bookingId,
    )
  })

  return { bookingCancellationOptions, isLoading, isError, refetch, isRefetching }
}
