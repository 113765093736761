import { qoursesApi } from '@/api/qourses.tsx'
import { MultipassProductName } from '@/components/Resolvers.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useGetMultipassProduct, {
  getMultipassProductQueryKey,
} from '@/hooks/multipasses/useGetMultipassProduct.tsx'
import { getMultipassProductsQueryKey } from '@/hooks/multipasses/useGetMultipassProducts.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, minDelay, sendGenericErrorNotification, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { ArchiveRestore, Loader2, Ticket } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function RestoreMultipassProductModal({
  multipassProductId,
}: {
  multipassProductId: string
}) {
  const { multipassProduct, isLoading, isError } = useGetMultipassProduct(multipassProductId)
  const [isRestoring, setIsRestoring] = useState(false)

  const queryClient = useQueryClient()

  const { t: translate } = useTranslation()

  const handleRestoreMultipassProduct = async () => {
    try {
      setIsRestoring(true)
      await minDelay(
        qoursesApi.multipass.multipassControllerRestoreMultipassProduct(multipassProduct.id),
        1000,
      )
      sendNotification(
        translate('modals.restoreMultipassProductModal.notification.title'),
        translate('modals.restoreMultipassProductModal.notification.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      queryClient.invalidateQueries(getMultipassProductsQueryKey({}))
      queryClient.invalidateQueries(getMultipassProductQueryKey(multipassProductId))
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
    } finally {
      setIsRestoring(false)
    }
  }

  if (isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('common.loading')}</DialogTitle>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('modals.restoreCourseGroupModal.errorState.title')}</DialogTitle>
          <DialogDescription>
            {translate('modals.restoreMultipassProductModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogHeader className="mb-2">
          <DialogTitle className="mt-6 sm:mt-2"></DialogTitle>
          <DialogTitle className="mt-6 flex flex-col gap-y-2 sm:mt-2">
            <div className="flex flex-row items-center justify-center text-sm text-muted-foreground sm:justify-start">
              <Ticket className="mr-1 size-4 text-muted-foreground" />
              <MultipassProductName multipassProductId={multipassProductId} />
            </div>
            <p>{translate('modals.restoreMultipassProductModal.title')}</p>
          </DialogTitle>
          <DialogDescription>
            {translate('modals.restoreMultipassProductModal.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </DialogHeader>
      <Button
        variant="indigo"
        className="bezel"
        disabled={isRestoring}
        onClick={handleRestoreMultipassProduct}
      >
        {isRestoring ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {translate('common.loading')}
          </>
        ) : (
          <>
            <ArchiveRestore className="mr-2 h-4 w-4" />
            {translate('modals.restoreMultipassProductModal.button')}
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
