import { Dialog, DialogContent } from '@/shadcn/components/ui/dialog.tsx' // shadcn dialog
import { Drawer, DrawerContent } from '@/shadcn/components/ui/drawer.tsx' // shadcn drawer
import { createResponsiveWrapper } from 'pushmodal'

export default createResponsiveWrapper({
  desktop: {
    Wrapper: Dialog,
    Content: DialogContent,
  },
  mobile: {
    Wrapper: Drawer,
    Content: DrawerContent,
  },
  breakpoint: 640,
})
