import { qoursesApi } from '@/api/qourses.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import { getCourseMeetingsQueryKey } from '@/hooks/courses/useGetCourseMeetings.tsx'
import useGetMeeting, { getMeetingQueryKey } from '@/hooks/meetings/useGetMeeting.tsx'
import { getMeetingsQueryKey } from '@/hooks/meetings/useGetMeetings.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Checkbox } from '@/shadcn/components/ui/checkbox.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, minDelay, sendGenericErrorNotification, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function CancelMeetingModal({ meetingId }: { meetingId: string }) {
  const { meeting, isLoading, isError } = useGetMeeting(meetingId)
  const [isDeleting, setIsDeleting] = useState(false)
  const [notifyAttendees, setNotifyAttendees] = useState(false)

  const queryClient = useQueryClient()

  const { t: translate } = useTranslation()

  const handleDeleteMeeting = async () => {
    try {
      setIsDeleting(true)
      await minDelay(
        qoursesApi.meeting.meetingControllerCancelMeeting(meeting.id, notifyAttendees),
        1000,
      )
      sendNotification(
        translate('pages.meetings.notifications.meeting-cancelled.title'),
        translate('pages.meetings.notifications.meeting-cancelled.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      queryClient.invalidateQueries(getCourseMeetingsQueryKey(meeting.courseId))
      queryClient.invalidateQueries(getMeetingsQueryKey({}))
      queryClient.invalidateQueries(getMeetingQueryKey(meeting.id))
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
    } finally {
      setIsDeleting(false)
    }
  }

  if (isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('common.loading')}</DialogTitle>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>
            {translate('modals.deleteMeetingConfirmationModal.errorState.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.deleteMeetingConfirmationModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {translate('modals.deleteMeetingConfirmationModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('modals.deleteMeetingConfirmationModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div className="mx-auto my-2 flex items-center justify-center">
        <div className="border-1 z-10 whitespace-nowrap rounded-lg border border-gray-200 bg-white p-3 text-gray-400">
          <span className="mr-2 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
            {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
          </span>
          {'|'}
          <span className="ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            {DateTime.fromISO(meeting.end).toLocaleString(DateTime.TIME_SIMPLE)}
          </span>
        </div>
      </div>
      <div className="my-2 mb-4 flex justify-start gap-x-2 text-xs">
        <Checkbox
          id="overrideTOS"
          checked={notifyAttendees}
          onClick={() => setNotifyAttendees(!notifyAttendees)}
        />
        <label htmlFor="overrideTOS" className="text-sm font-medium leading-none">
          {translate('modals.deleteMeetingConfirmationModal.notifyAttendees.title')}
          <p className="mt-1 text-xs text-gray-600">
            {translate('modals.deleteMeetingConfirmationModal.notifyAttendees.subtitle')}
          </p>
        </label>
      </div>
      <Button variant="indigo" disabled={isDeleting} onClick={handleDeleteMeeting}>
        {isDeleting ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {translate('common.loading')}
          </>
        ) : (
          translate('modals.deleteMeetingConfirmationModal.button')
        )}
      </Button>
    </Dynamic.Content>
  )
}
