/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicInstructorInvitationEntity } from '../models/PublicInstructorInvitationEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstructorCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get an Instructor Invitation by its secret
     * @param secret
     * @returns PublicInstructorInvitationEntity Instructor Invitation has been returned
     * @throws ApiError
     */
    public instructorCustomerControllerGetInstructorInvitationBySecret(
        secret: string,
    ): CancelablePromise<PublicInstructorInvitationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/instructor/invitation',
            query: {
                'secret': secret,
            },
            errors: {
                404: `Instructor Invitation has not been found`,
            },
        });
    }
    /**
     * Use an Instructor Invitation by its secret
     * @param secret
     * @returns PublicInstructorInvitationEntity Instructor Invitation has been returned
     * @throws ApiError
     */
    public instructorCustomerControllerUseInstructorInvitationBySecret(
        secret: string,
    ): CancelablePromise<PublicInstructorInvitationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/instructor/invitation/use',
            query: {
                'secret': secret,
            },
            errors: {
                404: `Instructor Invitation has not been found`,
            },
        });
    }
}
