import { useQuery } from '@tanstack/react-query'
import { CourseScheduleEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourseSchedule(courseScheduleId: string): {
  courseSchedule: CourseScheduleEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: courseSchedule,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getCourseScheduleQueryKey(courseScheduleId), async () => {
    return qoursesApi.scheduling.schedulingControllerGetCourseSchedule(courseScheduleId)
  })

  return { courseSchedule, isLoading, isError, refetch, isRefetching }
}

export const getCourseScheduleQueryKey = (courseScheduleId: string) => [
  'courseSchedule',
  courseScheduleId,
]
