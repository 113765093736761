/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from '../models/BookingEntity';
import type { FormSubmissionEntity } from '../models/FormSubmissionEntity';
import type { GetByPostRebateCodeDto } from '../models/GetByPostRebateCodeDto';
import type { OrderEntity } from '../models/OrderEntity';
import type { OrderWithPriceDetailsEntity } from '../models/OrderWithPriceDetailsEntity';
import type { PostChangeRebateCodeDto } from '../models/PostChangeRebateCodeDto';
import type { PublicBookingEntity } from '../models/PublicBookingEntity';
import type { PublicBookingWithPriceDetailsEntity } from '../models/PublicBookingWithPriceDetailsEntity';
import type { PublicPostBookingDto } from '../models/PublicPostBookingDto';
import type { PublicPostOrderDto } from '../models/PublicPostOrderDto';
import type { PublicRebateCodeEntity } from '../models/PublicRebateCodeEntity';
import type { PublicReturnOrderCheckoutDto } from '../models/PublicReturnOrderCheckoutDto';
import type { PublicReturnOrderEmbeddedCheckoutDto } from '../models/PublicReturnOrderEmbeddedCheckoutDto';
import type { PublicStripeLineItemEntity } from '../models/PublicStripeLineItemEntity';
import type { PublicStripePaymentProfileEntity } from '../models/PublicStripePaymentProfileEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create an order
     * @param requestBody
     * @returns OrderEntity The Order has been returned
     * @throws ApiError
     */
    public paymentPublicControllerCreateOrder(
        requestBody: PublicPostOrderDto,
    ): CancelablePromise<OrderEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get an order
     * @param orderId
     * @returns OrderWithPriceDetailsEntity The Order has been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetOrder(
        orderId: string,
    ): CancelablePromise<OrderWithPriceDetailsEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/order/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Get the Bookings of an Order
     * @param orderId
     * @returns PublicBookingWithPriceDetailsEntity The Bookings of the Order have been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetOrderBookings(
        orderId: string,
    ): CancelablePromise<Array<PublicBookingWithPriceDetailsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/order/{orderId}/bookings',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Get the Form Submissions of an Order
     * @param orderId
     * @returns FormSubmissionEntity The Form Submissions of the Order have been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetOrderFormSubmissions(
        orderId: string,
    ): CancelablePromise<Array<FormSubmissionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/order/{orderId}/formSubmissions',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Delete a booking out of an Order
     * @param orderId
     * @param bookingId
     * @returns PublicBookingEntity The Booking that has been deleted
     * @throws ApiError
     */
    public paymentPublicControllerDeleteOrderBooking(
        orderId: string,
        bookingId: string,
    ): CancelablePromise<PublicBookingEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/public/payment/order/{orderId}/bookings/{bookingId}',
            path: {
                'orderId': orderId,
                'bookingId': bookingId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Create a booking
     * @param orderId
     * @param requestBody
     * @returns BookingEntity The Booking has been returned
     * @throws ApiError
     */
    public paymentPublicControllerCreateBooking(
        orderId: string,
        requestBody: PublicPostBookingDto,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order/{orderId}/booking',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Order OR Meeting has not been found`,
                409: `Meeting has already been booked out`,
            },
        });
    }
    /**
     * Get Booking Rebate Code
     * @param orderId
     * @param bookingId
     * @returns PublicRebateCodeEntity The rebate code has been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetBookingRebateCode(
        orderId: string,
        bookingId: string,
    ): CancelablePromise<PublicRebateCodeEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/order/{orderId}/booking/{bookingId}/rebateCode',
            path: {
                'orderId': orderId,
                'bookingId': bookingId,
            },
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Change Booking Rebate Code
     * @param orderId
     * @param bookingId
     * @param requestBody
     * @returns PublicBookingEntity The rebate code has been applied
     * @throws ApiError
     */
    public paymentPublicControllerChangeBookingRebateCode(
        orderId: string,
        bookingId: string,
        requestBody: PostChangeRebateCodeDto,
    ): CancelablePromise<PublicBookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order/{orderId}/booking/{bookingId}/rebateCode',
            path: {
                'orderId': orderId,
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Remove a Rebate Code from a Booking of an Order
     * @param orderId
     * @param bookingId
     * @returns PublicBookingEntity The Rebate Code of a Booking has been deleted
     * @throws ApiError
     */
    public paymentPublicControllerRemoveBookingRebateCode(
        orderId: string,
        bookingId: string,
    ): CancelablePromise<PublicBookingEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/public/payment/order/{orderId}/bookings/{bookingId}/rebateCode',
            path: {
                'orderId': orderId,
                'bookingId': bookingId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Create checkout details for an Order
     * @param orderId
     * @returns PublicReturnOrderCheckoutDto The checkout details have been returned
     * @throws ApiError
     */
    public paymentPublicControllerCreateOrderCheckout(
        orderId: string,
    ): CancelablePromise<PublicReturnOrderCheckoutDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order/{orderId}/checkout',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Order has not been found`,
                425: `Order has been changed and requires review`,
            },
        });
    }
    /**
     * Get Order Rebate Code
     * @param orderId
     * @returns PublicRebateCodeEntity The Rebate Code has been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetOrderRebateCode(
        orderId: string,
    ): CancelablePromise<PublicRebateCodeEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/order/{orderId}/rebateCode',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Change Order Rebate Code
     * @param orderId
     * @param requestBody
     * @returns OrderWithPriceDetailsEntity The rebate code has been applied
     * @throws ApiError
     */
    public paymentPublicControllerChangeOrderRebateCode(
        orderId: string,
        requestBody: PostChangeRebateCodeDto,
    ): CancelablePromise<OrderWithPriceDetailsEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order/{orderId}/rebateCode',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Remove Order Rebate Code
     * @param orderId
     * @returns OrderWithPriceDetailsEntity The rebate code has been removed
     * @throws ApiError
     */
    public paymentPublicControllerRemoveOrderRebateCode(
        orderId: string,
    ): CancelablePromise<OrderWithPriceDetailsEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/public/payment/order/{orderId}/rebateCode',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Get eligible Bookings for Booking Rebate Code in Order
     * @param orderId
     * @param requestBody
     * @returns PublicBookingEntity The Bookings have been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetEligibleOrderBookingsForRebateCode(
        orderId: string,
        requestBody: GetByPostRebateCodeDto,
    ): CancelablePromise<Array<PublicBookingEntity>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order/{orderId}/rebateCode/eligibleBookings',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Create embedded checkout details for an Order
     * @param orderId
     * @returns PublicReturnOrderEmbeddedCheckoutDto The embedded checkout details have been returned
     * @throws ApiError
     */
    public paymentPublicControllerCreateOrderEmbeddedCheckout(
        orderId: string,
    ): CancelablePromise<PublicReturnOrderEmbeddedCheckoutDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/payment/order/{orderId}/embeddedCheckout',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Order has not been found`,
                425: `Order has been changed and requires review`,
            },
        });
    }
    /**
     * Get line items from stripe for order
     * @param orderId
     * @returns PublicStripeLineItemEntity The stripe line items for the order have been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetLineItemsForOrder(
        orderId: string,
    ): CancelablePromise<Array<PublicStripeLineItemEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/order/{orderId}/lineItems',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `Order has not been found`,
            },
        });
    }
    /**
     * Get a StripePaymentProfile
     * @param stripePaymentProfileId
     * @returns PublicStripePaymentProfileEntity The StripePaymentProfile has been returned
     * @throws ApiError
     */
    public paymentPublicControllerGetStripePaymentProfile(
        stripePaymentProfileId: string,
    ): CancelablePromise<PublicStripePaymentProfileEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/payment/stripePaymentProfile/{stripePaymentProfileId}',
            path: {
                'stripePaymentProfileId': stripePaymentProfileId,
            },
            errors: {
                404: `The StripePaymentProfile has not been found`,
            },
        });
    }
}
