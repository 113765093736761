/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PatchProfileInstructorDto = {
    /**
     * The handle of the instructor
     */
    handle?: string;
    /**
     * The bio of the instructor
     */
    bio?: string;
    /**
     * The pronouns of the instructor
     */
    pronouns?: PatchProfileInstructorDto.pronouns;
};
export namespace PatchProfileInstructorDto {
    /**
     * The pronouns of the instructor
     */
    export enum pronouns {
        HE_HIM = 'HE_HIM',
        SHE_HER = 'SHE_HER',
        THEY_THEM = 'THEY_THEM',
        NONE = 'NONE',
    }
}

