import useGetCourses from '@/hooks/courses/useGetCourses.tsx'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CourseEntity } from '../../../qourses-api-client'

export function CourseSelection(props: {
  selectedCourse: CourseEntity | null
  setSelectedCourse: Dispatch<SetStateAction<CourseEntity | null>>
  preselectedCourseId?: string | null
  disabled: boolean
}) {
  const { t: translate } = useTranslation()
  const { courses, isLoading, isError } = useGetCourses()

  const [courseJson, setCourseJson] = useState<string | null>(null)
  const hasPreselected = useRef(false)

  useEffect(() => {
    if (props.preselectedCourseId && !hasPreselected.current && courses.length > 0) {
      const preselectedCourse = courses.find((course) => course.id === props.preselectedCourseId)
      if (preselectedCourse) {
        const preselectedCourseJson = JSON.stringify({
          value: preselectedCourse,
          label: preselectedCourse.name,
        })
        setCourseJson(preselectedCourseJson)
        props.setSelectedCourse(preselectedCourse)
      }
      hasPreselected.current = true
    }
  }, [props.preselectedCourseId, courses, props.setSelectedCourse])

  useEffect(() => {
    if (courseJson) {
      const course = JSON.parse(courseJson).value as CourseEntity
      props.setSelectedCourse(course)
    } else {
      props.setSelectedCourse(null)
    }
  }, [courseJson, props.setSelectedCourse])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue placeholder={translate('dropdowns.courses.placeholder')}></SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const mappedCourses = courses.map((course) => ({
    value: course,
    label: course.name,
  }))

  return (
    <>
      <Select
        onValueChange={(value) => setCourseJson(value)}
        value={courseJson ?? undefined}
        disabled={props.disabled ?? false}
      >
        <SelectTrigger className="bg-white">
          <SelectValue placeholder={translate('dropdowns.courses.placeholder')}></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.courses.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              mappedCourses.map((course) => (
                <SelectItem key={course.value.id} value={JSON.stringify(course)}>
                  {course.label}
                </SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}
