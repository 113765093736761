import { APP_METADATA_CLAIM_NAMESPACE } from '@/SidebarLayoutOutlet.tsx'
import { useAuth0 } from '@auth0/auth0-react'

export default function useIsPlatformAdmin() {
  const { user, isAuthenticated, isLoading } = useAuth0()

  if (!isAuthenticated || isLoading || !user) return false

  const userMetadata = user[APP_METADATA_CLAIM_NAMESPACE]

  if (!userMetadata) {
    return false
  }

  return !!userMetadata.administrator
}
