import { qoursesApi } from '@/api/qourses.tsx'
import RebateCodeDisplay from '@/components/RebateCodeDisplay.tsx'
import { CustomerOrderRebateCodeSelection } from '@/components/entityDropdowns/CustomerOrderRebateCodeSelection.tsx'
import EligibleOrderBookingSelection from '@/components/entityDropdowns/EligibleOrderBookingSelection.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import { GetEndCustomerRebateCodesForOrderQueryKey } from '@/hooks/customer/useGetEndCustomerRebateCodesForOrder.tsx'
import { GetOrderBookingsPublicQueryKey } from '@/hooks/public/useGetOrderBookingsPublic.tsx'
import { GetOrderPublicQueryKey } from '@/hooks/public/useGetOrderPublic.tsx'
import {
  removeMeetingIdsFromLocalStorage,
  removeOrderFromLocalStorage,
} from '@/pages/public/booking/BookingUtilFunctions.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { ToastVariant, minDelay, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import { OTPInput, SlotProps } from 'input-otp'
import { Loader2, TicketCheck, Tickets, Undo2, UserRound } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PublicBookingEntity,
  PublicRebateCodeEntity,
  PublicRebateCodeWithUsageInfoEntity,
  RebateCodeEntity,
} from '../../../qourses-api-client'

type UrgencyLevel = 'critical' | 'warning' | 'normal'

export default function InputVoucherCodeModal({
  orderId,
  guestId,
  isAuthenticated,
  loginWithRedirect,
}: {
  orderId: string
  guestId?: string
  isAuthenticated: boolean
  loginWithRedirect: (options?: { appState: { returnTo: string } }) => void
}) {
  const queryClient = useQueryClient()

  const CODE_MAX_LENGTH = 7

  const [code, setCode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isApplying, setIsApplying] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [selectedRebateCode, setSelectedRebateCode] = useState<PublicRebateCodeEntity>(null)

  const { t: translate } = useTranslation()

  const otpRef = useRef<HTMLInputElement>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const [rebateCode, setRebateCode] = useState<PublicRebateCodeWithUsageInfoEntity>(undefined)

  const [selectedBooking, setSelectedBooking] = useState<PublicBookingEntity>()

  useEffect(() => {
    if (code.length === CODE_MAX_LENGTH) {
      setIsSubmitting(true)
      qoursesApi.rebatePublic
        .rebatePublicControllerGetRebateCode({ code: code, guestId: guestId ?? undefined })
        .then((rebateCode) => {
          setRebateCode(rebateCode)
          setIsSubmitting(false)
        })
        .catch(() => {
          setIsSubmitting(false)
          setIsInvalid(true)
        })
    }
  }, [code])

  useEffect(() => {
    if (selectedRebateCode) {
      qoursesApi.rebatePublic
        .rebatePublicControllerGetRebateCode({
          codeId: selectedRebateCode.id,
          guestId: guestId ?? undefined,
        })
        .then((rebateCode) => {
          setRebateCode(rebateCode)
          setIsSubmitting(false)
        })
        .catch(() => {
          setIsSubmitting(false)
          setIsInvalid(true)
        })
    }
  }, [selectedRebateCode])

  useEffect(() => {
    if (rebateCode && !rebateCode.archived) {
      setIsChecked(true)
    }
  }, [rebateCode])

  useEffect(() => {
    const handleScroll = () => {
      window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleApplyRebateCode = async () => {
    try {
      setIsApplying(true)
      if (rebateCode.scope === RebateCodeEntity.scope.BOOKING) {
        await minDelay(
          qoursesApi.paymentPublic.paymentPublicControllerChangeBookingRebateCode(
            orderId,
            selectedBooking?.id,
            {
              rebateCode: selectedRebateCode ? undefined : code,
              rebateCodeId: selectedRebateCode ? rebateCode.id : undefined,
            },
          ),
          1000,
        )
        sendNotification(
          translate('pages.public.booking.summary.couponNotifications.bookingCouponAdded.title'),
          translate(
            'pages.public.booking.summary.couponNotifications.bookingCouponAdded.subtitle',
            {
              name: rebateCode.name,
            },
          ),
          ToastVariant.Success,
        )
      }

      if (rebateCode.scope === RebateCodeEntity.scope.ORDER) {
        await minDelay(
          qoursesApi.paymentPublic.paymentPublicControllerChangeOrderRebateCode(orderId, {
            rebateCode: selectedRebateCode ? undefined : code,
            rebateCodeId: selectedRebateCode ? rebateCode.id : undefined,
          }),
          1000,
        )
        sendNotification(
          translate('pages.public.booking.summary.couponNotifications.orderCouponAdded.title'),
          translate('pages.public.booking.summary.couponNotifications.orderCouponAdded.subtitle', {
            name: rebateCode.name,
          }),
          ToastVariant.Success,
        )
      }
      await queryClient.invalidateQueries(GetOrderBookingsPublicQueryKey(orderId))
      await queryClient.invalidateQueries(GetOrderPublicQueryKey(orderId))
      await queryClient.invalidateQueries(GetEndCustomerRebateCodesForOrderQueryKey(orderId))
      setIsApplying(false)
      popAllModals()
    } catch (error) {
      setIsApplying(false)
      console.error(error)
    }
  }

  // Rebate code cannot be used anymore
  const rebateCodeExhausted = rebateCode?.usageInfo?.usagesLeft === 0
  // Rebate code has usages across its cap left. E.g. it has quite some usage left into it
  // const rebateCodeCapCrossedAndUsagesLeft =
  //   rebateCode?.usageInfo?.capCrossed && rebateCode?.usageInfo?.usagesLeft > 0
  // Rebate code has usages left and cap not crossed. E.g. it has not so many usages left anymore and its nearing its end
  const rebateCodeCapNotCrossedAndUsagesLeft =
    !rebateCode?.usageInfo?.capCrossed && rebateCode?.usageInfo?.usagesLeft > 0

  const calculateRebateCodeUrgency = (
    rebateCode: PublicRebateCodeWithUsageInfoEntity,
  ): UrgencyLevel => {
    const usageInfo = rebateCode.usageInfo

    if (!usageInfo) {
      return 'normal'
    }

    // Always critical if 5 or fewer uses left, regardless of percentage
    if (usageInfo.usagesLeft <= 5) {
      return 'critical'
    }

    // If cap is crossed, we don't show urgency
    if (usageInfo.capCrossed) {
      return 'normal'
    }

    // Calculate percentage of usages remaining
    const percentageLeft = (usageInfo.usagesLeft / rebateCode.maxUsages) * 100

    if (percentageLeft <= 10) {
      return 'critical'
    } else if (percentageLeft <= 50) {
      return 'warning'
    } else {
      return 'normal'
    }
  }

  return (
    <Dynamic.Content className="p-6 pt-0 sm:p-8 focus:ring-0 focus:border-0 min-h-fit max-h-fit">
      <motion.div className="pt-4 sm:pt-0">
        <DialogHeader className="mb-2 flex flex-row items-center text-left sm:pt-0">
          <Tickets className="w-8 h-8 mr-2" />
          <div>
            <DialogTitle>{translate('modals.inputVoucherCodeModal.title')}</DialogTitle>
            <DialogDescription>
              {translate('modals.inputVoucherCodeModal.subtitle')}
            </DialogDescription>
          </div>
        </DialogHeader>
      </motion.div>
      <AnimatePresence>
        <div className="flex items-center justify-center relative z-[100]">
          <div className="rounded-lg flex flex-col items-center">
            {isAuthenticated && !rebateCode && (
              <div className="my-4">
                <div className="flex-1">
                  <p className="text-sm mb-2 font-semibold">
                    {translate('dropdowns.customerOrderRebateCodeSelection.title')}
                  </p>
                  <CustomerOrderRebateCodeSelection
                    selectedRebateCode={selectedRebateCode}
                    setSelectedRebateCode={setSelectedRebateCode}
                    disabled={false}
                    loading={false}
                    orderId={orderId}
                  />
                </div>
                <div className="relative mt-4">
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-xs text-muted-foreground">
                      {translate('modals.inputVoucherCodeModal.divider')}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {!selectedRebateCode && (
              <div>
                <OTPInput
                  ref={otpRef}
                  disabled={isChecked || isSubmitting}
                  value={code}
                  onChange={(newValue) => {
                    //detect if a character has been removed (backspace)
                    if (newValue.length < code.length) {
                      setIsInvalid(false)
                    }
                    setCode(newValue)
                  }}
                  autoComplete={'off'}
                  inputMode={'text'}
                  placeholder={'XQRSRLS'}
                  spellCheck={false}
                  maxLength={7}
                  autoCorrect={'off'}
                  containerClassName="group flex items-center has-[:disabled]:opacity-30 uppercase z-[1000]"
                  render={({ slots }) => (
                    <>
                      <div className="flex">
                        {slots.slice(0, 1).map((slot, idx) => (
                          <Slot key={idx} {...slot} />
                        ))}
                      </div>
                      <FakeDash />
                      <div className="flex">
                        {slots.slice(1, 4).map((slot, idx) => (
                          <Slot key={idx} {...slot} />
                        ))}
                      </div>
                      <FakeDash />
                      <div className="flex">
                        {slots.slice(4, 7).map((slot, idx) => (
                          <Slot key={idx} {...slot} />
                        ))}
                      </div>
                    </>
                  )}
                />
              </div>
            )}
            {isInvalid && (
              <div className="mt-3 z-20 relative flex justify-center items-center gap-y-2 flex-col max-w-xs text-center">
                <div className="p-2 text-sm rounded-lg border-0 bg-amber-300/10 bg-gradient-to-b from-red-500/10 to-orange-200/10 ring-1 ring-red-300 font-semibold text-red-900">
                  {translate('modals.inputVoucherCodeModal.rebateCodeInvalid')}
                </div>
              </div>
            )}
            {!isInvalid && rebateCode && (
              <div>
                {rebateCode.usageInfo && (
                  <div className="mt-3 z-20 relative flex justify-center items-center gap-y-2 flex-col max-w-xs text-center">
                    {rebateCodeCapNotCrossedAndUsagesLeft && (
                      <>
                        {calculateRebateCodeUrgency(rebateCode) === 'critical' && (
                          <div className="p-2 text-sm rounded-lg border-0 bg-amber-300/10 bg-gradient-to-b from-red-500/10 to-orange-200/10 ring-1 ring-red-300 font-semibold text-red-900">
                            {translate('modals.inputVoucherCodeModal.rebateUsageUrgency.critical', {
                              usageCount: rebateCode.usageInfo.usagesLeft,
                            })}
                          </div>
                        )}
                        {calculateRebateCodeUrgency(rebateCode) === 'warning' && (
                          <div className="p-2 text-sm rounded-lg border-0 bg-yellow-300/10 bg-gradient-to-b from-yellow-500/10 to-yellow-100/10 ring-1 ring-yellow-300 font-semibold text-yellow-900">
                            {translate('modals.inputVoucherCodeModal.rebateUsageUrgency.warning', {
                              usageCount: rebateCode.usageInfo.usagesLeft,
                            })}
                          </div>
                        )}
                        {calculateRebateCodeUrgency(rebateCode) === 'normal' && (
                          <div className="p-2 text-sm rounded-lg border-0 bg-blue-300/10 bg-gradient-to-b from-blue-500/10 to-blue-100/10 ring-1 ring-blue-300 font-semibold text-blue-900">
                            {translate('modals.inputVoucherCodeModal.rebateUsageUrgency.normal', {
                              usageCount: rebateCode.usageInfo.usagesLeft,
                            })}
                          </div>
                        )}
                      </>
                    )}
                    {rebateCodeExhausted && (
                      <div className="p-2 text-sm rounded-lg border-0 bg-gray-300/10 bg-gradient-to-b from-gray-500/10 to-gray-100/10 ring-1 ring-gray-300 font-semibold text-gray-900">
                        {translate('modals.inputVoucherCodeModal.rebateUsageUrgency.exhausted')}
                      </div>
                    )}
                  </div>
                )}
                <RebateCodeDisplay
                  rebateCodeId={rebateCode.id}
                  rebateCodeExhausted={rebateCodeExhausted}
                />
                {!isAuthenticated && rebateCode.registeredUsersOnly ? (
                  <div className="mt-4">
                    <div className="relative flex max-w-xs items-center z-[100] p-4 text-sm rounded-lg border-0 bg-indigo-300/10 bg-gradient-to-b from-indigo-500/10 to-indigo-200/10 ring-1 ring-indigo-300 font-semibold text-indigo-900">
                      <div className="flex flex-col gap-y-1">
                        <p>
                          {translate('modals.inputVoucherCodeModal.requireRegisteredUser.title')}
                        </p>
                        <p className="text-xs text-muted-foreground font-normal">
                          {translate('modals.inputVoucherCodeModal.requireRegisteredUser.subtitle')}
                        </p>
                        <Button
                          onClick={() => {
                            removeOrderFromLocalStorage()
                            removeMeetingIdsFromLocalStorage()
                            loginWithRedirect({
                              appState: {
                                returnTo: `${window.location.pathname}${window.location.search}`,
                              },
                            })
                          }}
                          variant={'indigo'}
                          className="bezel mt-2"
                        >
                          <UserRound className="w-4 h-4 mr-2" />
                          {translate('modals.inputVoucherCodeModal.requireRegisteredUser.button')}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {!rebateCodeExhausted &&
                      rebateCode &&
                      rebateCode.scope === RebateCodeEntity.scope.BOOKING && (
                        <div className="flex flex-wrap relative z-[10000] mt-2">
                          <p className="text-sm mb-2 font-semibold">
                            {translate('dropdowns.eligibleOrderBookingSelection.title')}
                          </p>
                          <EligibleOrderBookingSelection
                            setSelectedBooking={setSelectedBooking}
                            orderId={orderId}
                            code={code ?? null}
                            rebateCodeId={selectedRebateCode?.id ?? null}
                            disabled={false}
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            )}
            <div className="flex flex-1 gap-y-2 flex-row gap-x-2 justify-center sm:mt-6 pt-6 border-gray-200 relative z-[10000]">
              {(rebateCode || isInvalid) && (
                <Button
                  variant="secondary"
                  className="bg-gray-300 bg-gradient-to-b from-gray-200 to-gray-100 border border-gray-200 text-gray-600 font-semibold"
                  disabled={!code && isApplying}
                  onClick={() => {
                    setCode('')
                    setRebateCode(undefined)
                    setIsChecked(false)
                    setIsInvalid(false)
                    setSelectedRebateCode(undefined)
                    otpRef.current.focus()
                  }}
                >
                  <Undo2 className="w-4 h-4 mr-2" />
                  {translate('modals.inputVoucherCodeModal.resetButton')}
                </Button>
              )}
              {!rebateCodeExhausted && !isInvalid && (
                <Button
                  variant="indigo"
                  className="relative overflow-hidden"
                  ref={submitButtonRef}
                  disabled={
                    !isChecked ||
                    isApplying ||
                    rebateCodeExhausted ||
                    (rebateCode &&
                      rebateCode.scope === RebateCodeEntity.scope.BOOKING &&
                      !selectedBooking) ||
                    (!isAuthenticated && rebateCode.registeredUsersOnly)
                  }
                  onClick={handleApplyRebateCode}
                >
                  {isChecked && !isApplying && (
                    <motion.div
                      initial={{
                        rotate: 45,
                      }}
                      animate={{
                        rotate: 45,
                        x: [-200, 200],
                        opacity: [0.4, 0.8, 0.4],
                      }}
                      transition={{
                        repeat: Infinity,
                        duration: 1.5,
                        repeatDelay: 0.5,
                      }}
                      className="z-100 absolute h-40 w-20 bg-white/0 bg-gradient-to-r from-white/0 via-white/30 to-white/0"
                    />
                  )}
                  {isApplying ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {translate('common.loading')}
                    </>
                  ) : (
                    <>
                      <TicketCheck className="w-4 h-4 mr-2" />
                      {translate('modals.inputVoucherCodeModal.submitButton')}
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </AnimatePresence>
    </Dynamic.Content>
  )
}

function Slot(props: SlotProps) {
  return (
    <div
      className={cn(
        'relative w-8 h-12 text-[1.5rem] sm:w-10 sm:h-14 sm:text-[2rem]',
        'flex items-center justify-center',
        'transition-all duration-300',
        'border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md',
        'group-hover:border-accent-foreground/40 group-focus-within:border-accent-foreground/20',
        'outline outline-0 outline-accent-foreground/20',
        { 'outline-4 outline-indigo-500': props.isActive },
      )}
      autoCorrect={'off'}
    >
      <motion.div
        animate={{
          scale: props.char && [1, 1.1, 1, 1.05, 1],
        }}
        transition={{
          duration: 0.3,
          delay: Math.random() * 0.1,
        }}
        className="group-has-[input[data-input-otp-placeholder-shown]]:opacity-20"
      >
        {props.char ?? props.placeholderChar}
      </motion.div>
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  )
}

// You can emulate a fake textbox caret!
function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-8 bg-black" />
    </div>
  )
}

// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div className="flex w-10 justify-center items-center">
      <div className="w-3 h-1 rounded-full bg-border" />
    </div>
  )
}
