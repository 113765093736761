import { useQuery } from '@tanstack/react-query'
import { OrderEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetOrder(orderId: string): {
  order: OrderEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: order,
    isLoading,
    isError,
    refetch,
  } = useQuery(['order', orderId], async () => {
    return qoursesApi.payment.paymentControllerGetOrder(orderId)
  })

  return { order, isLoading, isError, refetch }
}

export const GetOrderQueryKey = (orderId: string) => ['order', orderId]
