/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostCustomerInstructorDto = {
    handle: string;
    bio?: string;
    pronouns?: PostCustomerInstructorDto.pronouns;
};
export namespace PostCustomerInstructorDto {
    export enum pronouns {
        HE_HIM = 'HE_HIM',
        SHE_HER = 'SHE_HER',
        THEY_THEM = 'THEY_THEM',
        NONE = 'NONE',
    }
}

