import { useQuery } from '@tanstack/react-query'
import { PublicMeetingEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourseGroupMeetingsPublic(courseGroupId: string): {
  courseGroupMeetings: PublicMeetingEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: courseGroupMeetings,
    isLoading,
    isError,
  } = useQuery(['public', 'courseGroup', courseGroupId, 'meetings'], async () => {
    return qoursesApi.courseGroupPublic.courseGroupPublicControllerGetCourseGroupMeetings(
      courseGroupId,
    )
  })

  let isEmpty = true

  if (courseGroupMeetings === undefined) {
    return { courseGroupMeetings: [], isLoading, isEmpty, isError }
  }

  if (courseGroupMeetings.length > 0) {
    isEmpty = false
  }

  return { courseGroupMeetings, isLoading, isEmpty, isError }
}
