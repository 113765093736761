import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { StripePaymentProfileEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCurrentOrganizationPaymentProfile(): {
  paymentProfile: StripePaymentProfileEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: paymentProfile,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    ['stripePaymentProfile'],
    async () => {
      return qoursesApi.payment.paymentControllerGetTenantStripePaymentProfile('false')
    },
    {
      retry: (failureCount, error: AxiosError) => {
        if (error.status === 404) {
          return false
        }

        return failureCount < 3
      },
    },
  )

  // Ignore 404 as an 'error'
  const isNon404Error = error?.status === 404 ? false : isError

  return { paymentProfile, isLoading, isError: isNon404Error, refetch }
}
