import { qoursesApi } from '@/api/qourses.tsx'
import {
  CourseBookableSelection,
  IdentifiableBookableBackoffice,
  isCourseGroup,
  isMeeting,
} from '@/components/entityDropdowns/CourseBookableSelection.tsx'
import { CourseSelection } from '@/components/entityDropdowns/CourseSelection'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useGetBooking, { getBookingQueryKey } from '@/hooks/orders/useGetBooking.tsx'
import { GetBookingsQueryKey } from '@/hooks/orders/useGetBookings.tsx'
import { GetOrderBookingsWithLineItemQueryKey } from '@/hooks/orders/useGetOrderBookingsWithLineItem.tsx'
import { GetOrderBookingsPublicQueryKey } from '@/hooks/public/useGetOrderBookingsPublic.tsx'
import { Button } from '@/shadcn/components/ui/button'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog'
import { Select, SelectTrigger, SelectValue } from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { ToastVariant, minDelay, removePointerEventsFromBody, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { Calendar, Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function TransferBookingModal({ bookingId }: { bookingId: string }) {
  const { t: translate } = useTranslation()
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedBookable, setSelectedBookable] = useState<IdentifiableBookableBackoffice>(null)
  const [submitting, setSubmitting] = useState(false)
  const queryClient = useQueryClient()

  // Prevent radix from creating a sticky pointer events none and breaking the page
  removePointerEventsFromBody()

  const { booking, isLoading, isError } = useGetBooking(bookingId)

  const handleTransfer = async () => {
    try {
      setSubmitting(true)
      if (!selectedBookable) {
        throw new Error('No bookable selected')
      }

      if (isMeeting(selectedBookable.bookable, selectedBookable.bookableType)) {
        await minDelay(
          qoursesApi.payment.paymentControllerReplaceBookableOfBooking(booking.id, {
            meetingId: selectedBookable.bookable.id,
          }),
          1000,
        )
      }
      if (isCourseGroup(selectedBookable.bookable, selectedBookable.bookableType)) {
        await minDelay(
          qoursesApi.payment.paymentControllerReplaceBookableOfBooking(booking.id, {
            courseGroupId: selectedBookable.bookable.id,
          }),
          1000,
        )
      }
      sendNotification(
        translate('modals.transferBookingModal.notification.title'),
        translate('modals.transferBookingModal.notification.subtitle', {
          newBookable: selectedCourse.name,
        }),
        ToastVariant.Success,
      )
    } catch (error) {
      console.error(error)
      sendNotification(
        translate('modals.transferBookingModal.errorState.title'),
        translate('modals.transferBookingModal.errorState.subtitle'),
        ToastVariant.Error,
      )
    } finally {
      setSubmitting(false)
      popAllModals()
      await queryClient.invalidateQueries(GetOrderBookingsPublicQueryKey(booking.orderId))
      await queryClient.invalidateQueries(GetBookingsQueryKey())
      await queryClient.invalidateQueries(getBookingQueryKey(booking.id))
      await queryClient.invalidateQueries(GetOrderBookingsWithLineItemQueryKey(booking.orderId))
    }
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('modals.transferBookingModal.errorState.title')}</DialogTitle>
          <DialogDescription>
            {translate('modals.transferBookingModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-4">
        <DialogTitle>{translate('modals.transferBookingModal.title')}</DialogTitle>
        <DialogDescription>{translate('modals.transferBookingModal.subtitle')}</DialogDescription>
      </DialogHeader>

      {isLoading ? (
        <div className="space-y-2">
          <Skeleton className="h-5 w-3/4" />
          <Skeleton className="h-5 w-1/2" />
          <Skeleton className="h-5 w-3/5" />
        </div>
      ) : (
        <>
          <div className="px-2 pb-2">
            <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
              <Calendar className="mr-1 size-3" />
              {translate('modals.transferBookingModal.course.label')}
            </label>
            <CourseSelection
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              disabled={false}
            />
            <p className="mt-1 text-sm text-muted-foreground">
              {translate('modals.transferBookingModal.course.subtitle')}
            </p>
          </div>

          <div className="p-2">
            <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
              <Calendar className="mr-1 size-3" />
              {translate('modals.transferBookingModal.bookable.label')}
            </label>
            {selectedCourse ? (
              <CourseBookableSelection
                courseId={selectedCourse.id}
                selectedBookable={selectedBookable}
                setSelectedBookable={setSelectedBookable}
                disabled={!selectedCourse}
              />
            ) : (
              <Select disabled={true}>
                <SelectTrigger className="bg-white">
                  <SelectValue
                    placeholder={translate('dropdowns.courseMeetings.placeholder')}
                  ></SelectValue>
                </SelectTrigger>
              </Select>
            )}
            <p className="mt-1 text-sm text-muted-foreground">
              {translate('modals.transferBookingModal.bookable.subtitle')}
            </p>
          </div>
        </>
      )}

      <div className="mt-8 flex justify-end">
        <Button
          variant="secondary"
          className="mr-2"
          onClick={() => {
            popAllModals()
          }}
        >
          {translate('common.buttons.cancel')}
        </Button>
        <Button
          variant="indigo"
          disabled={submitting || !selectedBookable}
          onClick={handleTransfer}
        >
          {submitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {translate('common.loading')}
            </>
          ) : (
            translate('modals.transferBookingModal.button')
          )}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
