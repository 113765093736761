import { qoursesApi } from '@/api/qourses.tsx'
import { MultipassProductName } from '@/components/Resolvers.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useGetMultipassProduct, {
  getMultipassProductQueryKey,
} from '@/hooks/multipasses/useGetMultipassProduct.tsx'
import { getMultipassProductsQueryKey } from '@/hooks/multipasses/useGetMultipassProducts.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, minDelay, sendGenericErrorNotification, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { Archive, Loader2, Ticket } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ArchiveMultipassProductModal({
  multipassProductId,
}: {
  multipassProductId: string
}) {
  const { multipassProduct, isLoading, isError } = useGetMultipassProduct(multipassProductId)
  const [isDeleting, setIsDeleting] = useState(false)

  const queryClient = useQueryClient()

  const { t: translate } = useTranslation()

  const handleArchiveMultipassProduct = async () => {
    try {
      setIsDeleting(true)
      await minDelay(
        qoursesApi.multipass.multipassControllerArchiveMultipassProduct(multipassProduct.id),
        1000,
      )
      sendNotification(
        translate('modals.archiveMultipassProductModal.notification.title'),
        translate('modals.archiveMultipassProductModal.notification.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      queryClient.invalidateQueries(getMultipassProductsQueryKey({}))
      queryClient.invalidateQueries(getMultipassProductQueryKey(multipassProductId))
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
    } finally {
      setIsDeleting(false)
    }
  }

  if (isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('common.loading')}</DialogTitle>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('modals.archiveCourseGroupModal.errorState.title')}</DialogTitle>
          <DialogDescription>
            {translate('modals.archiveMultipassProductModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogHeader className="mb-2">
          <DialogTitle className="mt-6 sm:mt-2"></DialogTitle>
          <DialogTitle className="mt-6 flex flex-col gap-y-2 sm:mt-2">
            <div className="flex flex-row items-center justify-center text-sm text-muted-foreground sm:justify-start">
              <Ticket className="mr-1 size-4 text-muted-foreground" />
              <MultipassProductName multipassProductId={multipassProductId} />
            </div>
            <p>{translate('modals.archiveMultipassProductModal.title')}</p>
          </DialogTitle>
          <DialogDescription>
            {translate('modals.archiveMultipassProductModal.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </DialogHeader>
      <Button
        variant="destructive"
        className="bezel"
        disabled={isDeleting}
        onClick={handleArchiveMultipassProduct}
      >
        {isDeleting ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {translate('common.loading')}
          </>
        ) : (
          <>
            <Archive className="mr-2 h-4 w-4" />
            {translate('modals.archiveMultipassProductModal.button')}
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
