/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { CourseGroupEntity } from '../models/CourseGroupEntity';
import type { MultipassProductEntity } from '../models/MultipassProductEntity';
import type { PublicRebateCodeEntity } from '../models/PublicRebateCodeEntity';
import type { StripePaymentProfileEntity } from '../models/StripePaymentProfileEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RebateCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Rebate Codes
     * @returns PublicRebateCodeEntity Rebate Codes have been returned
     * @throws ApiError
     */
    public rebateCustomerControllerGetRebateCodes(): CancelablePromise<Array<PublicRebateCodeEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/rebate/code',
        });
    }
    /**
     * Get a Rebate Code
     * @param rebateCodeId
     * @returns PublicRebateCodeEntity Rebate Code has been returned
     * @throws ApiError
     */
    public rebateCustomerControllerGetRebateCode(
        rebateCodeId: string,
    ): CancelablePromise<PublicRebateCodeEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/rebate/code/{rebateCodeId}',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Remove a Rebate Code
     * @param rebateCodeId
     * @returns PublicRebateCodeEntity Rebate Code has been removed
     * @throws ApiError
     */
    public rebateCustomerControllerRemoveRebateCode(
        rebateCodeId: string,
    ): CancelablePromise<PublicRebateCodeEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/customer/rebate/code/{rebateCodeId}',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Stripe Payment Profiles
     * @param rebateCodeId
     * @returns StripePaymentProfileEntity Stripe Payment Profiles have been returned
     * @throws ApiError
     */
    public rebateCustomerControllerGetRebateCodeEligibleStripePaymentProfiles(
        rebateCodeId: string,
    ): CancelablePromise<Array<StripePaymentProfileEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/rebate/code/{rebateCodeId}/stripePaymentProfile',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Courses
     * @param rebateCodeId
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public rebateCustomerControllerGetRebateCodeEligibleCourses(
        rebateCodeId: string,
    ): CancelablePromise<Array<CourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/rebate/code/{rebateCodeId}/course',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Course Groups
     * @param rebateCodeId
     * @returns CourseGroupEntity Course Groups have been returned
     * @throws ApiError
     */
    public rebateCustomerControllerGetRebateCodeEligibleCourseGroups(
        rebateCodeId: string,
    ): CancelablePromise<Array<CourseGroupEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/rebate/code/{rebateCodeId}/courseGroup',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Multipass Products
     * @param rebateCodeId
     * @returns MultipassProductEntity Multipass Products have been returned
     * @throws ApiError
     */
    public rebateCustomerControllerGetRebateCodeEligibleMultipassProducts(
        rebateCodeId: string,
    ): CancelablePromise<Array<MultipassProductEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/rebate/code/{rebateCodeId}/multipassProduct',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
}
