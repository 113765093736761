import {
  CourseGroupNamePublic,
  CourseNamePublicViaMeetingId,
  MeetingTimePublic,
  MultipassProductNamePublic,
} from '@/components/Resolvers.tsx'
import useGetEligibleOrderBookingsForRebateCode from '@/hooks/public/useGetEligibleOrderBookingsForRebateCode.tsx'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select'
import { Skeleton } from '@/shadcn/components/ui/skeleton'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PublicBookingEntity } from '../../../qourses-api-client'

interface BookingSelectionProps {
  setSelectedBooking: Dispatch<SetStateAction<PublicBookingEntity | null>>
  orderId: string
  code: string | null
  rebateCodeId: string | null
  preselectedBookingId?: string | null
  disabled?: boolean
}

const EligibleOrderBookingSelection = ({
  setSelectedBooking,
  orderId,
  code,
  rebateCodeId,
  preselectedBookingId,
  disabled = false,
}: BookingSelectionProps) => {
  const { bookings, isLoading, isError, isEmpty } = useGetEligibleOrderBookingsForRebateCode(
    orderId,
    code,
    rebateCodeId,
  )

  const { t: translate } = useTranslation()

  const [bookingJson, setBookingJson] = useState<string | null>(null)
  const hasPreselected = useRef(false)
  const hasAutoSelected = useRef(false)

  // Handle preselected booking
  useEffect(() => {
    if (preselectedBookingId && !hasPreselected.current && bookings.length > 0) {
      const preselectedBooking = bookings.find((booking) => booking.id === preselectedBookingId)
      if (preselectedBooking) {
        const preselectedBookingJson = JSON.stringify({
          value: preselectedBooking,
          label: preselectedBooking.id,
        })
        setBookingJson(preselectedBookingJson)
        setSelectedBooking(preselectedBooking)
      }
      hasPreselected.current = true
    }
  }, [preselectedBookingId, bookings, setSelectedBooking])

  // Auto-select single booking
  useEffect(() => {
    if (
      !isLoading &&
      !isError &&
      bookings.length === 1 &&
      !bookings[0].multipassId &&
      !hasAutoSelected.current &&
      !preselectedBookingId &&
      !bookingJson
    ) {
      const singleBooking = bookings[0]
      const singleBookingJson = JSON.stringify({
        value: singleBooking,
        label: singleBooking.id,
      })
      setBookingJson(singleBookingJson)
      setSelectedBooking(singleBooking)
      hasAutoSelected.current = true
    }
  }, [bookings, isLoading, isError, preselectedBookingId, bookingJson, setSelectedBooking])

  useEffect(() => {
    if (bookingJson) {
      const booking = JSON.parse(bookingJson).value as PublicBookingEntity
      setSelectedBooking(booking)
    } else {
      setSelectedBooking(null)
    }
  }, [bookingJson, setSelectedBooking])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue
            placeholder={translate('dropdowns.eligibleOrderBookingSelection.placeholder')}
          />
        </SelectTrigger>
      </Select>
    )
  }

  const mappedBookings = bookings.map((booking: PublicBookingEntity) => ({
    value: booking,
    label: booking.id,
  }))

  const typedSelectedBooking = bookingJson && (JSON.parse(bookingJson).value as PublicBookingEntity)

  return (
    <Select
      onValueChange={(value) => setBookingJson(value)}
      value={bookingJson ?? undefined}
      disabled={disabled || isEmpty}
    >
      <SelectTrigger className="bg-white text-xs p-2">
        <SelectValue placeholder={translate('dropdowns.eligibleOrderBookingSelection.placeholder')}>
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex flex-col items-start">
              {typedSelectedBooking && typedSelectedBooking.meetingId && (
                <>
                  <BookingMeetingDisplay booking={typedSelectedBooking} />
                </>
              )}
            </div>
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{translate('dropdowns.eligibleOrderBookingSelection.title')}</SelectLabel>
          {isLoading && (
            <div className="space-y-2">
              <Skeleton className="h-6 bg-gray-200" />
              <Skeleton className="h-6 bg-gray-200" />
              <Skeleton className="h-6 bg-gray-200" />
            </div>
          )}
          {!isLoading &&
            mappedBookings.map((booking) => (
              <SelectItem
                key={booking.value.id}
                value={JSON.stringify(booking)}
                className="text-xs"
                disabled={!!booking.value.multipassId}
              >
                {booking.value.multipassId && (
                  <p className="text-gray-900 text-xs mb-1">
                    {translate(
                      'dropdowns.eligibleOrderBookingSelection.multipassAppliedBookingsNotAvailableHint',
                    )}
                  </p>
                )}
                {booking.value.meetingId && <BookingMeetingDisplay booking={booking.value} />}
                {booking.value.courseGroupId && (
                  <CourseGroupNamePublic courseGroupId={booking.value.courseGroupId} />
                )}
                {booking.value.multipassProductId && (
                  <MultipassProductNamePublic
                    multipassProductId={booking.value.multipassProductId}
                  />
                )}
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

function BookingMeetingDisplay({ booking }: { booking: PublicBookingEntity }) {
  if (booking.meetingId) {
    return (
      <>
        <CourseNamePublicViaMeetingId meetingId={booking.meetingId} /> -{' '}
        <MeetingTimePublic meetingId={booking.meetingId} />
      </>
    )
  }
}

export default EligibleOrderBookingSelection
