import { useQuery } from '@tanstack/react-query'
import { BookingEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetOrderBookings(orderId: string): {
  bookings: BookingEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: bookings,
    isLoading,
    isError,
  } = useQuery(['orders', orderId, 'bookings'], async () => {
    return qoursesApi.payment.paymentControllerGetOrderBookings(orderId)
  })

  let isEmpty = true

  if (bookings === undefined) {
    return { bookings: [], isLoading, isEmpty, isError }
  }

  if (bookings.length > 0) {
    isEmpty = false
  }

  return { bookings, isLoading, isEmpty, isError }
}

export const GetOrderBookingsQueryKey = (orderId: string) => ['orders', orderId, 'bookings']
