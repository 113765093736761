/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { CourseGroupEntity } from '../models/CourseGroupEntity';
import type { CustomerEntity } from '../models/CustomerEntity';
import type { GuestEntity } from '../models/GuestEntity';
import type { MultipassProductEntity } from '../models/MultipassProductEntity';
import type { PatchRebateCodeDto } from '../models/PatchRebateCodeDto';
import type { PostIssueRebateCodeDto } from '../models/PostIssueRebateCodeDto';
import type { PostRebateCodeDto } from '../models/PostRebateCodeDto';
import type { RebateCodeEntity } from '../models/RebateCodeEntity';
import type { StripePaymentProfileEntity } from '../models/StripePaymentProfileEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RebateService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Rebate Codes
     * @returns RebateCodeEntity Rebate Codes have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodes(): CancelablePromise<Array<RebateCodeEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code',
        });
    }
    /**
     * Create a Rebate Code
     * @param requestBody
     * @returns RebateCodeEntity Rebate Code has been created
     * @throws ApiError
     */
    public rebateControllerCreateRebateCode(
        requestBody: PostRebateCodeDto,
    ): CancelablePromise<RebateCodeEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/rebate/code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update a Rebate Code
     * @param rebateCodeId
     * @param requestBody
     * @returns RebateCodeEntity Rebate Code has been updated
     * @throws ApiError
     */
    public rebateControllerUpdateRebateCode(
        rebateCodeId: string,
        requestBody: PatchRebateCodeDto,
    ): CancelablePromise<RebateCodeEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/rebate/code/{rebateCodeId}',
            path: {
                'rebateCodeId': rebateCodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Rebate Code
     * @param rebateCodeId
     * @returns RebateCodeEntity Rebate Code has been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCode(
        rebateCodeId: string,
    ): CancelablePromise<RebateCodeEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Issue a Rebate Code to a Customer
     * @param rebateCodeId
     * @param requestBody
     * @returns RebateCodeEntity Rebate Code has been issued
     * @throws ApiError
     */
    public rebateControllerIssueRebateCode(
        rebateCodeId: string,
        requestBody: PostIssueRebateCodeDto,
    ): CancelablePromise<RebateCodeEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/rebate/code/{rebateCodeId}/issue',
            path: {
                'rebateCodeId': rebateCodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Rebate Code eligible Stripe Payment Profiles
     * @param rebateCodeId
     * @returns StripePaymentProfileEntity Stripe Payment Profiles have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodeEligibleStripePaymentProfiles(
        rebateCodeId: string,
    ): CancelablePromise<Array<StripePaymentProfileEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}/stripePaymentProfile',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Courses
     * @param rebateCodeId
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodeEligibleCourses(
        rebateCodeId: string,
    ): CancelablePromise<Array<CourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}/course',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Course Groups
     * @param rebateCodeId
     * @returns CourseGroupEntity Course Groups have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodeEligibleCourseGroups(
        rebateCodeId: string,
    ): CancelablePromise<Array<CourseGroupEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}/courseGroup',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Rebate Code eligible Multipass Products
     * @param rebateCodeId
     * @returns MultipassProductEntity Multipass Products have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodeEligibleMultipassProducts(
        rebateCodeId: string,
    ): CancelablePromise<Array<MultipassProductEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}/multipassProduct',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Customers that have been issued a Rebate Code
     * @param rebateCodeId
     * @returns CustomerEntity Customers have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodeCustomers(
        rebateCodeId: string,
    ): CancelablePromise<Array<CustomerEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}/customer',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
    /**
     * Get Guests that have been issued a Rebate Code
     * @param rebateCodeId
     * @returns GuestEntity Guests have been returned
     * @throws ApiError
     */
    public rebateControllerGetRebateCodeGuests(
        rebateCodeId: string,
    ): CancelablePromise<Array<GuestEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/rebate/code/{rebateCodeId}/guest',
            path: {
                'rebateCodeId': rebateCodeId,
            },
        });
    }
}
