/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetByPostRebateCodeDto } from '../models/GetByPostRebateCodeDto';
import type { PublicRebateCodeWithUsageInfoEntity } from '../models/PublicRebateCodeWithUsageInfoEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RebatePublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Rebate Code
     * @param requestBody
     * @returns PublicRebateCodeWithUsageInfoEntity Rebate Code has been returned
     * @throws ApiError
     */
    public rebatePublicControllerGetRebateCode(
        requestBody: GetByPostRebateCodeDto,
    ): CancelablePromise<PublicRebateCodeWithUsageInfoEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/rebate/code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
