import { useQuery } from '@tanstack/react-query'
import { BookingWithStripeLineItemEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetOrderBookingsWithLineItem(orderId: string): {
  bookingsWithLineItem: BookingWithStripeLineItemEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: bookingsWithLineItem,
    isLoading,
    isError,
  } = useQuery(GetOrderBookingsWithLineItemQueryKey(orderId), async () => {
    return qoursesApi.payment.paymentControllerGetOrderBookingsWithLineItems(orderId)
  })

  let isEmpty = true

  if (bookingsWithLineItem === undefined) {
    return { bookingsWithLineItem: [], isLoading, isEmpty, isError }
  }

  if (bookingsWithLineItem.length > 0) {
    isEmpty = false
  }

  return { bookingsWithLineItem, isLoading, isEmpty, isError }
}

export const GetOrderBookingsWithLineItemQueryKey = (orderId: string) => {
  return ['orders', orderId, 'bookingsWithLineItem']
}
