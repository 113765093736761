import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { PublicRebateCodeEntity } from '../../../qourses-api-client'

export default function useGetEndCustomerRebateCodesForOrder(orderId: string): {
  rebateCodes: PublicRebateCodeEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: rebateCodes,
    isLoading,
    isError,
  } = useQuery(
    GetEndCustomerRebateCodesForOrderQueryKey(orderId),
    async () => {
      if (orderId === undefined) {
        return
      }
      return qoursesApi.paymentCustomer.paymentCustomerControllerGetUseableRebateCodesForOrder(
        orderId,
      )
    },
    {
      enabled: !!orderId,
    },
  )

  let isEmpty = true

  if (rebateCodes === undefined) {
    return { rebateCodes: [], isLoading, isEmpty, isError }
  }

  if (rebateCodes.length > 0) {
    isEmpty = false
  }

  return { rebateCodes, isLoading, isEmpty, isError }
}

export const GetEndCustomerRebateCodesForOrderQueryKey = (orderId: string) => [
  'customer',
  'rebateCodes',
  orderId,
]
