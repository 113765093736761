import {
  currentOrderMeetings,
  orderLocalStorageKey,
} from '@/pages/public/booking/SessionParams.tsx'
import { OrderEntity } from '../../../../qourses-api-client'

export function addMeetingIdToLocalStorage(bookingId: string) {
  const existingStorage = JSON.parse(localStorage.getItem(currentOrderMeetings) ?? '[]')
  localStorage.setItem(currentOrderMeetings, JSON.stringify([...existingStorage, bookingId]))
}

export function removeMeetingIdFromLocalStorage(bookingId: string) {
  const existingStorage = JSON.parse(localStorage.getItem(currentOrderMeetings) ?? '[]')
  localStorage.setItem(
    currentOrderMeetings,
    JSON.stringify(existingStorage.filter((id: string) => id !== bookingId)),
  )
}

export function getMeetingIdsFromLocalStorage(): string[] {
  return JSON.parse(localStorage.getItem(currentOrderMeetings) ?? '[]')
}

export function addOrderToLocalStorage(order: OrderEntity) {
  localStorage.setItem(orderLocalStorageKey, JSON.stringify(order))
}

export function removeOrderFromLocalStorage() {
  localStorage.removeItem(orderLocalStorageKey)
}

export function removeMeetingIdsFromLocalStorage() {
  localStorage.removeItem(currentOrderMeetings)
}
