import { useQuery } from '@tanstack/react-query'
import { InstructorEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetInstructor(instructorId: string): {
  instructor: InstructorEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: instructor,
    isLoading,
    isError,
    refetch,
  } = useQuery(['instructor', instructorId], async () => {
    return qoursesApi.instructor.instructorControllerGetInstructor(instructorId)
  })

  return { instructor, isLoading, isError, refetch }
}
