import { useQuery } from '@tanstack/react-query'
import { PublicMeetingEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetMeetingPublic(meetingId: string): {
  meeting: PublicMeetingEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: meeting,
    isLoading,
    isError,
  } = useQuery(['public', 'meeting', meetingId], async () => {
    return qoursesApi.meetingPublic.meetingPublicControllerGetMeeting(meetingId)
  })

  if (meeting === undefined) {
    return { meeting: {} as PublicMeetingEntity, isLoading, isError }
  }

  return { meeting, isLoading, isError }
}
