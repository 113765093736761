import { qoursesApi } from '@/api/qourses.tsx'
import { CourseName } from '@/components/Resolvers.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import { getMeetingsQueryKey } from '@/hooks/meetings/useGetMeetings.tsx'
import { getCourseScheduleQueryKey } from '@/hooks/schedules/useGetCourseSchedule.tsx'
import useGetScheduleBatchMeetings from '@/hooks/schedules/useGetScheduleBatchMeetings.tsx'
import { getScheduleBatchesQueryKey } from '@/hooks/schedules/useGetScheduleBatches.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Checkbox } from '@/shadcn/components/ui/checkbox.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, minDelay, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { Calendar, Loader2, SquareStack, Timer, Trash } from 'lucide-react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ApproveScheduleBatchModal({
  scheduleBatchId,
}: {
  scheduleBatchId: string
}) {
  const { meetings, isLoading: isLoadingScheduleBatchMeetings } =
    useGetScheduleBatchMeetings(scheduleBatchId)

  const [isLoading, setLoading] = useState(false)
  const [isLoadingReject, setLoadingReject] = useState(false)

  const { t: translate } = useTranslation()

  const queryClient = useQueryClient()

  const [skippedMeetings, setSkippedMeetings] = useState<string[]>([])

  useEffect(() => {
    if (!isLoadingScheduleBatchMeetings) {
      const skippedMeetings = meetings
        .filter((meeting) => meeting.skipped)
        .map((meeting) => meeting.id)
      setSkippedMeetings(skippedMeetings)
    }
  }, [isLoadingScheduleBatchMeetings])

  const handleApproveScheduleBatch = async () => {
    try {
      setLoading(true)

      for (const meeting of meetings) {
        if (skippedMeetings.includes(meeting.id)) {
          if (!meeting.skipped) {
            await qoursesApi.meeting.meetingControllerUpdateMeeting(meeting.id, {
              skipped: true,
            })
          }
        } else {
          if (meeting.skipped) {
            await qoursesApi.meeting.meetingControllerUpdateMeeting(meeting.id, {
              skipped: false,
            })
          }
        }
      }

      await minDelay(
        qoursesApi.scheduling.schedulingControllerApproveScheduleBatch(scheduleBatchId),
        1000,
      )

      sendNotification(
        translate('modals.approveScheduleBatchModal.notification.title'),
        translate('modals.approveScheduleBatchModal.notification.subtitle'),
        ToastVariant.Success,
      )

      await queryClient.invalidateQueries(getScheduleBatchesQueryKey())
      await queryClient.invalidateQueries(getMeetingsQueryKey())
      await queryClient.invalidateQueries(getCourseScheduleQueryKey(scheduleBatchId))
      await queryClient.invalidateQueries(['scheduleBatch', scheduleBatchId])
      popAllModals()
    } catch (e) {
      setLoading(false)
    }
  }

  const handleRejectScheduleBatch = async () => {
    try {
      setLoadingReject(true)
      await minDelay(
        qoursesApi.scheduling.schedulingControllerRejectScheduleBatch(scheduleBatchId),
        1000,
      )

      sendNotification(
        translate('modals.approveScheduleBatchModal.rejectNotification.title'),
        translate('modals.approveScheduleBatchModal.rejectNotification.subtitle'),
        ToastVariant.Success,
      )

      await queryClient.invalidateQueries(getScheduleBatchesQueryKey())
      await queryClient.invalidateQueries(getMeetingsQueryKey())
      await queryClient.invalidateQueries(getCourseScheduleQueryKey(scheduleBatchId))
      await queryClient.invalidateQueries(['scheduleBatch', scheduleBatchId])
      popAllModals()
    } catch (e) {
      setLoadingReject(false)
    }
  }

  return (
    <Dynamic.Content className="p-6 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 flex flex-col gap-y-2 sm:mt-2">
          <div className="flex flex-row items-center justify-center text-sm text-muted-foreground sm:justify-start">
            <Calendar className="mr-1 size-4 text-muted-foreground" />
            <CourseName courseId={meetings[0]?.courseId} />
          </div>
          <p>{translate('modals.approveScheduleBatchModal.title')}</p>
        </DialogTitle>
        <DialogDescription>
          {translate('modals.approveScheduleBatchModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div className="mb-8 mt-4 space-y-8">
        <div className="relative grid w-full items-center gap-2 px-2 sm:px-0">
          <div className="border-b-2 border-dashed pb-2 text-sm font-medium text-muted-foreground">
            {meetings.length} Termine
          </div>
          <ul className="max-h-52 w-full overflow-auto">
            {meetings.map((meeting, index) => (
              <motion.li
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: meetings.length < 10 ? index * 0.1 : 0.0,
                }}
                className="mb-2 mt-1 flex flex-wrap items-center justify-between gap-x-2 gap-y-1 pb-1 pr-4 text-sm font-medium last:mb-14 sm:last:mb-8"
              >
                <Checkbox
                  id={meeting.id}
                  className="border-indigo-700 data-[state=checked]:bg-indigo-500 data-[state=unchecked]:bg-indigo-50"
                  checked={!skippedMeetings.includes(meeting.id) && !meeting.skipped}
                  onCheckedChange={(checked) => {
                    setSkippedMeetings((prev) =>
                      checked ? prev.filter((id) => id !== meeting.id) : [...prev, meeting.id],
                    )
                  }}
                />
                <label
                  className="flex flex-1 cursor-pointer flex-col justify-between gap-x-1 gap-y-1 sm:flex-row"
                  htmlFor={meeting.id}
                >
                  {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED)} -{' '}
                  {DateTime.fromISO(meeting.end).toLocaleString(DateTime.TIME_24_SIMPLE)}
                  <div className="flex flex-wrap font-normal text-muted-foreground">
                    {meeting.durationInMinutes} {translate('common.time.minutes')}
                    <Timer className="ml-1 h-4 w-4" />
                  </div>
                </label>
              </motion.li>
            ))}
          </ul>
          <div className="pointer-events-none absolute bottom-0 h-full w-full bg-gradient-to-t from-white to-transparent to-30% sm:to-20%" />
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-x-2 gap-y-2">
        <Button
          variant="indigo"
          disabled={
            isLoading ||
            isLoadingScheduleBatchMeetings ||
            isLoadingReject ||
            meetings.length - skippedMeetings.length === 0
          }
          onClick={handleApproveScheduleBatch}
        >
          {isLoading ? (
            <>
              {translate('common.loading')}
              <Loader2 className="ml-2 h-4 w-4 animate-spin" />
            </>
          ) : (
            <>
              {/* If there are no skipped meetings, we show a different text for approving the whole batch */}
              {skippedMeetings.length === 0
                ? translate('modals.approveScheduleBatchModal.approveBatchButton')
                : translate('modals.approveScheduleBatchModal.approveButton', {
                    count: meetings.length - skippedMeetings.length,
                  })}
              <SquareStack className="ml-2 h-4 w-4" />
            </>
          )}
        </Button>
        <Button
          variant="destructive"
          className="bezel"
          disabled={isLoadingReject || isLoadingScheduleBatchMeetings || isLoading}
          onClick={handleRejectScheduleBatch}
        >
          {isLoadingReject ? (
            <>
              {translate('common.loading')}
              <Loader2 className="ml-2 h-4 w-4 animate-spin" />
            </>
          ) : (
            <>
              {translate('modals.approveScheduleBatchModal.declineButton')}
              <Trash className="ml-2 h-4 w-4" />
            </>
          )}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
