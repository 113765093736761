import { qoursesApi } from '@/api/qourses.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useGetMeeting, { getMeetingQueryKey } from '@/hooks/meetings/useGetMeeting.tsx'
import { getMeetingsQueryKey } from '@/hooks/meetings/useGetMeetings.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, minDelay, sendGenericErrorNotification, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { Archive, Loader2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ArchiveMeetingModal({ meetingId }: { meetingId: string }) {
  const { meeting, isLoading, isError } = useGetMeeting(meetingId)
  const [isDeleting, setIsDeleting] = useState(false)

  const queryClient = useQueryClient()

  const { t: translate } = useTranslation()

  const handleArchiveMeeting = async () => {
    try {
      setIsDeleting(true)
      await minDelay(qoursesApi.meeting.meetingControllerArchiveMeeting(meeting.id), 1000)
      sendNotification(
        translate('modals.archiveMeetingModal.notification.title'),
        translate('modals.archiveMeetingModal.notification.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      queryClient.invalidateQueries(getMeetingsQueryKey({}))
      queryClient.invalidateQueries(getMeetingQueryKey(meetingId))
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
    } finally {
      setIsDeleting(false)
    }
  }

  if (isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('common.loading')}</DialogTitle>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('modals.archiveMeetingModal.errorState.title')}</DialogTitle>
          <DialogDescription>
            {translate('modals.archiveMeetingModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogHeader className="mb-2">
          <DialogTitle className="mt-6 sm:mt-2"></DialogTitle>
          <DialogTitle className="mt-6 flex flex-col gap-y-2 sm:mt-2">
            <p>{translate('modals.archiveMeetingModal.title')}</p>
          </DialogTitle>
          <DialogDescription>{translate('modals.archiveMeetingModal.subtitle')}</DialogDescription>
        </DialogHeader>
      </DialogHeader>
      <div className="mx-auto my-2 flex items-center justify-center">
        <div className="border-1 z-10 whitespace-nowrap rounded-lg border border-gray-200 bg-white p-3 text-gray-400">
          <span className="mr-2 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
            {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
          </span>
          {'|'}
          <span className="ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            {DateTime.fromISO(meeting.end).toLocaleString(DateTime.TIME_SIMPLE)}
          </span>
        </div>
      </div>
      <Button
        variant="destructive"
        className="bezel"
        disabled={isDeleting}
        onClick={handleArchiveMeeting}
      >
        {isDeleting ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {translate('common.loading')}
          </>
        ) : (
          <>
            <Archive className="mr-2 h-4 w-4" />
            {translate('modals.archiveMeetingModal.button')}
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
