/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PublicInstructorEntity = {
    id: string;
    organizationId: string;
    pronouns: PublicInstructorEntity.pronouns | null;
    bio: string | null;
    handle: string;
    firstName: string;
    lastName: string;
    archived: boolean;
    testMode: boolean;
    legalName: string | null;
};
export namespace PublicInstructorEntity {
    export enum pronouns {
        HE_HIM = 'HE_HIM',
        SHE_HER = 'SHE_HER',
        THEY_THEM = 'THEY_THEM',
        NONE = 'NONE',
    }
}

