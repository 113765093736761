import { popAllModals } from '@/components/modals'
import Dynamic from '@/components/modals/dynamic'
import { Button } from '@/shadcn/components/ui/button'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog'
import { CalendarClock, SquareStack } from 'lucide-react'
import { useTranslation } from 'react-i18next'

interface CourseCreatedModalProps {
  courseId: string
  navigate: (path: string) => void
}

export default function CourseCreatedModal({ courseId, navigate }: CourseCreatedModalProps) {
  const { t: translate } = useTranslation()

  const handleNavigateToMeetings = () => {
    popAllModals()
    navigate(`/meetings/create?courseId=${courseId}`)
  }

  const handleNavigateToOverview = () => {
    popAllModals()
    navigate('/courses')
  }

  const handleNavigateToSchedule = () => {
    popAllModals()
    navigate(`/meetings/schedules/create?courseId=${courseId}`)
  }

  const handleNavigateToCourseGroup = () => {
    popAllModals()
    navigate(`/courses/createGroup?courseId=${courseId}`)
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-6">
        <DialogTitle className="mt-3 text-center mb-2">
          {translate('pages.create-course.successModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('pages.create-course.successModal.subtitle')}
        </DialogDescription>
      </DialogHeader>

      <div className="grid grid-flow-row-dense grid-cols-1 gap-3 sm:grid-cols-2">
        <Button onClick={handleNavigateToMeetings} variant="indigo">
          <SquareStack className="mr-2 h-4 w-4" />
          {translate('pages.create-course.successModal.linkScheduleMeetings')}
        </Button>

        <Button onClick={handleNavigateToSchedule} variant="indigo">
          <CalendarClock className="mr-2 h-4 w-4" />
          {translate('pages.create-course.successModal.linkCreateSchedule')}
        </Button>

        <Button onClick={handleNavigateToCourseGroup} variant="indigo" className="col-span-full">
          <CalendarClock className="mr-2 h-4 w-4" />
          {translate('pages.create-course.successModal.linkCreateCourseGroup')}
        </Button>

        <Button variant="outline" onClick={handleNavigateToOverview} className="col-span-full">
          {translate('pages.create-course.successModal.linkGoToOverview')}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
