import { useQuery } from '@tanstack/react-query'
import { PublicMultipassProductEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetMultipassProductPublic(multipassProductId: string): {
  multipassProduct: PublicMultipassProductEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: multipassProduct,
    isLoading,
    isError,
  } = useQuery(['public', 'multipassProduct', multipassProductId], async () => {
    return qoursesApi.multipassPublic.multipassPublicControllerGetMultipassProduct(
      multipassProductId,
    )
  })

  return { multipassProduct, isLoading, isError }
}
