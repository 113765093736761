import { useQuery } from '@tanstack/react-query'
import { CourseScheduleEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourseSchedules(courseId: string): {
  schedules: CourseScheduleEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: schedules,
    isLoading,
    isError,
  } = useQuery(GetCourseSchedules(courseId), async () => {
    if (courseId === undefined) {
      return
    }
    return qoursesApi.course.courseControllerGetCourseSchedules(courseId)
  })

  let isEmpty = true

  if (schedules === undefined) {
    return { schedules: [], isLoading, isEmpty, isError }
  }

  if (schedules.length > 0) {
    isEmpty = false
  }

  return { schedules, isLoading, isEmpty, isError }
}

export const GetCourseSchedules = (courseId: string) => ['courses', 'schedule', courseId]
