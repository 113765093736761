import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import React from 'react'
import deTranslation from './locales/de.json'
import enTranslation from './locales/en.json'

// Initialize i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Fallback language
    debug: false, // Enable debug mode for development

    // Load translations
    resources: {
      en: {
        translation: enTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
    interpolation: {
      escapeValue: false, // React handles the escaping
    },
    detection: {
      order: ['navigator'],
    },
  })

interface TranslationProviderProps {
  children: React.ReactNode
}

const TranslationProvider = (props: TranslationProviderProps) => {
  return <>{props.children}</>
}

export default TranslationProvider
