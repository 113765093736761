/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MeetingScheduleEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    courseScheduleId: string;
    weekday: MeetingScheduleEntity.weekday;
    /**
     * The time of day the meetings will be created at (24 hours)
     */
    timeOfDay: string;
    /**
     * The duration of the meeting in minutes
     */
    durationInMinutes: number;
    /**
     * The nth weekday of the month the meeting will be created on. Only used with MONTHLY type.
     */
    nthWeekdayOfMonth: number | null;
    /**
     * The nth of last weekday of the month the meeting will be created on. Only used with MONTHLY type.
     */
    nthLastWeekDayOfMonth: number | null;
    /**
     * The nth day of the month the meeting will be created on. Only used with MONTHLY type.
     */
    nthDayOfMonth: number | null;
    archived: boolean;
};
export namespace MeetingScheduleEntity {
    export enum weekday {
        MONDAY = 'MONDAY',
        TUESDAY = 'TUESDAY',
        WEDNESDAY = 'WEDNESDAY',
        THURSDAY = 'THURSDAY',
        FRIDAY = 'FRIDAY',
        SATURDAY = 'SATURDAY',
        SUNDAY = 'SUNDAY',
    }
}

