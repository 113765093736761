import { useQuery } from '@tanstack/react-query'
import { MultipassProductWithCourseIdsEntityDto } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetMultipassProduct(multipassProductId: string): {
  multipassProduct: MultipassProductWithCourseIdsEntityDto | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: multipassProduct,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getMultipassProductQueryKey(multipassProductId), async () => {
    return qoursesApi.multipass.multipassControllerGetMultipassProduct(multipassProductId)
  })

  return { multipassProduct, isLoading, isError, refetch, isRefetching }
}

export const getMultipassProductQueryKey = (multipassProductId: string) => [
  'multipassProduct',
  multipassProductId,
]
