import { useQuery } from '@tanstack/react-query'
import { PublicCourseGroupWithBookingInfoEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourseGroupPublic(courseGroupId: string): {
  courseGroup: PublicCourseGroupWithBookingInfoEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: courseGroup,
    isLoading,
    isError,
  } = useQuery(['public', 'courseGroup', courseGroupId], async () => {
    return qoursesApi.courseGroupPublic.courseGroupPublicControllerGetCourseGroup(courseGroupId)
  })

  return { courseGroup, isLoading, isError }
}
