/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CourseScheduleEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    type: CourseScheduleEntity.type;
    /**
     * Indicates how many intervals (of the given type) are skipped between each cycle (i.e. 1 month; 1 week; 1 day).
     */
    skipInterval: number;
    startDate: string;
    endDate: string | null;
    courseId: string | null;
    /**
     * IANA Timezone identifier that the CourseSchedule follows
     */
    timezone: string;
    active: boolean;
    /**
     * The last meeting that has been created following this CourseSchedule. Used as a starting point for the next evaluation.
     */
    evaluatedUntil: string | null;
    archived: boolean;
};
export namespace CourseScheduleEntity {
    export enum type {
        DAILY = 'DAILY',
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
    }
}

