import useGetCourseSchedules from '@/hooks/courses/useGetCourseSchedules'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CourseScheduleEntity } from '../../../qourses-api-client'

export function CourseScheduleSelection(props: {
  selectedSchedule: CourseScheduleEntity | null
  setSelectedSchedule: Dispatch<SetStateAction<CourseScheduleEntity | null>>
  courseId: string
  preselectedScheduleId?: string | null
  disabled: boolean
  disableFinishedSchedules?: boolean
}) {
  const { t: translate } = useTranslation()
  const { schedules, isLoading, isError } = useGetCourseSchedules(props.courseId)

  const [scheduleJson, setScheduleJson] = useState<string | null>(null)
  const hasPreselected = useRef(false)

  // Filter schedules for the selected course and non-archived ones
  const filteredSchedules = schedules.filter(
    (schedule) => schedule.courseId === props.courseId && !schedule.archived,
  )

  useEffect(() => {
    if (props.preselectedScheduleId && !hasPreselected.current && filteredSchedules.length > 0) {
      const preselectedSchedule = filteredSchedules.find(
        (schedule) => schedule.id === props.preselectedScheduleId,
      )
      if (preselectedSchedule) {
        const preselectedScheduleJson = JSON.stringify({
          value: preselectedSchedule,
          label: translate(
            preselectedSchedule.endDate
              ? 'pages.course-detail.basic-details.schedule.card.title'
              : 'pages.course-detail.basic-details.schedule.card.titleNoEnd',
            {
              startDate: DateTime.fromISO(preselectedSchedule.startDate).toLocaleString(
                DateTime.DATE_MED,
              ),
              endDate: DateTime.fromISO(preselectedSchedule.endDate).toLocaleString(
                DateTime.DATE_MED,
              ),
            },
          ),
        })
        setScheduleJson(preselectedScheduleJson)
        props.setSelectedSchedule(preselectedSchedule)
      }
      hasPreselected.current = true
    }
  }, [props.preselectedScheduleId, filteredSchedules, props.setSelectedSchedule])

  useEffect(() => {
    if (scheduleJson) {
      const schedule = JSON.parse(scheduleJson).value as CourseScheduleEntity
      props.setSelectedSchedule(schedule)
    } else {
      props.setSelectedSchedule(null)
    }
  }, [scheduleJson, props.setSelectedSchedule])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue placeholder={translate('dropdowns.schedules.placeholder')}></SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const mappedSchedules = filteredSchedules.map((schedule) => ({
    value: schedule,
    disabled:
      schedule.endDate &&
      schedule.evaluatedUntil &&
      DateTime.fromISO(schedule.endDate) < DateTime.fromISO(schedule.evaluatedUntil),
    label: translate(
      schedule.endDate
        ? 'pages.course-detail.basic-details.schedule.card.title'
        : 'pages.course-detail.basic-details.schedule.card.titleNoEnd',
      {
        startDate: DateTime.fromISO(schedule.startDate).toLocaleString(DateTime.DATE_MED),
        endDate: DateTime.fromISO(schedule.endDate).toLocaleString(DateTime.DATE_MED),
      },
    ),
  }))

  return (
    <>
      <Select
        onValueChange={(value) => setScheduleJson(value)}
        value={scheduleJson ?? undefined}
        disabled={props.disabled ?? false}
      >
        <SelectTrigger className="bg-white">
          <SelectValue placeholder={translate('dropdowns.schedules.placeholder')}></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.schedules.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-6 bg-gray-200" />
                <Skeleton className="h-6 bg-gray-200" />
                <Skeleton className="h-6 bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              mappedSchedules.length > 0 &&
              mappedSchedules.map((schedule) => (
                <SelectItem
                  key={schedule.value.id}
                  value={JSON.stringify({ value: schedule.value, label: schedule.label })}
                  disabled={props.disableFinishedSchedules && schedule.disabled}
                >
                  {schedule.label}
                </SelectItem>
              ))}
            {!isLoading && mappedSchedules.length === 0 && (
              <SelectItem
                key={'empty'}
                disabled={true}
                value={translate('dropdowns.schedules.empty.title')}
              >
                {translate('dropdowns.schedules.empty.title')}
              </SelectItem>
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}

export default CourseScheduleSelection
