import { useQuery } from '@tanstack/react-query'
import { OrderWithPriceDetailsEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetOrderPublic(orderId: string): {
  order: OrderWithPriceDetailsEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: order,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(GetOrderPublicQueryKey(orderId), async () => {
    return qoursesApi.paymentPublic.paymentPublicControllerGetOrder(orderId)
  })

  if (order === undefined) {
    return {
      order: {} as OrderWithPriceDetailsEntity,
      isLoading,
      isError,
      refetch,
      isRefetching,
    }
  }

  return { order, isLoading, isError, refetch, isRefetching }
}

export const GetOrderPublicQueryKey = (orderId: string) => ['public', 'order', orderId]
