import { qoursesApi } from '@/api/qourses.tsx'
import { ChangeIndicator } from '@/components/ChangeIndicator.tsx'
import EmptyState from '@/components/EmptyState.tsx'
import { Switch } from '@/components/Switch.tsx'
import useGetForm, { getFormQueryKey } from '@/hooks/forms/useGetForm.tsx'
import useGetFormQuestions, {
  getFormQuestionsQueryKey,
} from '@/hooks/forms/useGetFormQuestions.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Checkbox } from '@/shadcn/components/ui/checkbox.tsx'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/shadcn/components/ui/collapsible.tsx'
import { Input } from '@/shadcn/components/ui/input.tsx'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { RadioGroup, RadioGroupItem } from '@/shadcn/components/ui/radio-group.tsx'
import { Textarea } from '@/shadcn/components/ui/textarea.tsx'
import { ToastVariant, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, Reorder, motion } from 'framer-motion'
import {
  ArrowDownToLine,
  ArrowUpToLine,
  CalendarDays,
  ChevronDown,
  ChevronUp,
  ClipboardCheck,
  ClipboardList,
  ListTodo,
  MessageCircleQuestionIcon,
  MessageSquarePlus,
  MessageSquareText,
  NotebookPen,
  Pencil,
  X,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { FormQuestionEntity } from '../../../qourses-api-client'
import PageHeader from '../../components/PageHeader.tsx'
import { Title } from '../courses/Title.tsx'

export default function FormDetail() {
  const params = useParams()

  const { t: translate } = useTranslation()

  const {
    formQuestions,
    isLoading: formQuestionsLoading,
    isError,
    refetch: refetchFormQuestions,
    isFetching,
  } = useGetFormQuestions(params.id)

  const {
    form,
    isLoading: isLoadingForm,
    isError: isErrorForm,
    refetch: refetchForm,
  } = useGetForm(params.id)

  const [formName, setFormName] = useState('')
  const [formDescription, setFormDescription] = useState('')
  const [activeFormQuestions, setActiveFormQuestions] = useState<FormQuestionEntity[]>([])

  const [editing, setEditing] = useState(false)
  const [changed, setChanged] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [defaultsPopulated, setDefaultsPopulated] = useState(false)

  const queryClient = useQueryClient()

  const handleToggleEditMode = async () => {
    if (changed && editing && formQuestions) {
      setEditing(false)
      setActiveFormQuestions(formQuestions)
      setFormName(form.name)
      setFormDescription(form.description)
      setChanged(false)
      sendNotification(
        translate('pages.form-details.change-revert.title'),
        translate('pages.form-details.change-revert.subtitle'),
        ToastVariant.Success,
      )
    } else {
      setEditing(!editing)
    }
  }

  const formQuestionUpdateCallback = (
    updatedFormQuestion: FormQuestionEntity,
    deleteQuestion?: boolean,
  ) => {
    const updatedFormQuestions = activeFormQuestions.map((fq) => {
      if (fq.id === updatedFormQuestion.id) {
        if (deleteQuestion) {
          return null
        } else {
          return updatedFormQuestion
        }
      }
      return fq
    })
    setActiveFormQuestions(updatedFormQuestions.filter((fq) => fq !== null))
  }

  const weightUpdateCallback = (
    operation: 'increase' | 'decrease' | 'sink' | 'float',
    formQuestion: FormQuestionEntity,
  ) => {
    //acquire weights of the neighbouring form questions
    const currentIndex = activeFormQuestions.findIndex((fq) => fq.id === formQuestion.id)
    const sortedFormQuestions = activeFormQuestions.sort((a, b) => a.weight - b.weight)
    const lighterFormQuestion = sortedFormQuestions[currentIndex - 1]
    const heavierFormQuestion = sortedFormQuestions[currentIndex + 1]

    // calculate new weight
    let newWeight: number
    if (operation === 'decrease') {
      if (lighterFormQuestion) {
        const previousFormQuestionWeight = lighterFormQuestion.weight
        newWeight = previousFormQuestionWeight - 1
      } else {
        // There is no lighter form question that the current one
        return
      }
    }
    if (operation === 'increase') {
      if (heavierFormQuestion) {
        const nextFormQuestionWeight = heavierFormQuestion.weight
        newWeight = nextFormQuestionWeight + 1
      } else {
        // There is no heavier form question that the current one
        return
      }
    }
    if (operation === 'sink') {
      const lastFormQuestionWeight = sortedFormQuestions[sortedFormQuestions.length - 1].weight
      newWeight = lastFormQuestionWeight + 1
    }
    if (operation === 'float') {
      const firstFormQuestionWeight = sortedFormQuestions[0].weight
      newWeight = firstFormQuestionWeight - 1
    }

    const updatedFormQuestions = activeFormQuestions.map((fq) => {
      if (fq.id === formQuestion.id) {
        return {
          ...fq,
          weight: newWeight,
        }
      }
      return fq
    })
    // sort by weight
    const updatedFormQuestionsSorted = updatedFormQuestions.sort((a, b) => a.weight - b.weight)
    setActiveFormQuestions(updatedFormQuestionsSorted)
  }

  function isValidFormQuestion(formQuestion: FormQuestionEntity) {
    return formQuestion.question && formQuestion.questionType
  }

  const handleUpdateForm = async () => {
    try {
      setSubmitting(true)

      //detect which questions have been deleted by comparing activeFormQuestions and formQuestions
      const deletedFormQuestions = formQuestions.filter(
        (fq) => !activeFormQuestions.map((afq) => afq.id).includes(fq.id),
      )

      // Changes on the name or description of the form
      if (formName !== form.name || formDescription !== form.description) {
        await qoursesApi.survey.surveyControllerUpdateForm(
          form.id,
          {
            name: formName,
            description: formDescription,
          },
          'false',
        )
      }

      for (const deletedFormQuestion of deletedFormQuestions) {
        await qoursesApi.survey.surveyControllerDeleteFormQuestion(deletedFormQuestion.id, 'false')
      }

      for (const formQuestion of activeFormQuestions) {
        if (formQuestion.id.startsWith('new')) {
          if (isValidFormQuestion(formQuestion)) {
            await qoursesApi.survey.surveyControllerCreateFormQuestion(
              {
                formId: form.id,
                question: formQuestion.question,
                questionType: formQuestion.questionType,
                required: formQuestion.required,
                selectOptions: formQuestion.selectOptions as any,
                weight: formQuestion.weight,
              },
              'false',
            )
          }
        } else {
          if (isValidFormQuestion(formQuestion)) {
            await qoursesApi.survey.surveyControllerUpdateFormQuestion(
              formQuestion.id,
              {
                question: formQuestion.question,
                questionType: formQuestion.questionType,
                required: formQuestion.required,
                selectOptions: formQuestion.selectOptions as any,
                weight: formQuestion.weight,
              },
              'false',
            )
          }
        }
      }
      sendNotification(
        translate('pages.form-details.save-changes.success.title'),
        translate('pages.form-details.save-changes.success.subtitle'),
        ToastVariant.Success,
      )
      await queryClient.invalidateQueries(getFormQueryKey(form.id))
      await queryClient.invalidateQueries(getFormQuestionsQueryKey(form.id))
      await refetchFormQuestions()
      await refetchForm()
    } catch (e) {
      console.error(e)
      sendNotification(
        translate('pages.form-details.save-changes.error.title'),
        translate('pages.form-details.save-changes.error.subtitle'),
        ToastVariant.Error,
      )
    } finally {
      setSubmitting(false)
      setEditing(false)
      setChanged(false)
    }
  }

  const pageRef = useRef(null)

  // This is needed to actually 'rerender' after we submit new changes
  // loading will only be true on the first initial load
  useEffect(() => {
    if (!isFetching && !formQuestionsLoading) {
      setActiveFormQuestions(formQuestions)
    }
  }, [isFetching])

  useEffect(() => {
    if (form && activeFormQuestions && !defaultsPopulated && !formQuestionsLoading) {
      setActiveFormQuestions(activeFormQuestions)
      setFormName(form.name)
      setFormDescription(form.description)
      setDefaultsPopulated(true)
    }
  }, [formQuestionsLoading, isLoadingForm])

  useEffect(() => {
    if (
      JSON.stringify(activeFormQuestions) !== JSON.stringify(formQuestions) ||
      form?.name !== formName ||
      form?.description !== formDescription
    ) {
      setChanged(true)
    } else {
      setChanged(false)
    }
  }, [activeFormQuestions, formName, formDescription])

  useEffect(() => {
    if (!formQuestionsLoading) {
      setActiveFormQuestions(formQuestions)
    }
  }, [formQuestionsLoading])

  const pageHeaderTitle = translate('pages.form-details.pageHeaderTitle')

  if (formQuestionsLoading || isLoadingForm) {
    return (
      <>
        <PageHeader title={pageHeaderTitle} />
        <Skeleton className={'h-40 w-full'} />
      </>
    )
  }

  if (isError || isErrorForm) {
    return <EmptyState isError={true} />
  }

  return (
    <div className="relative" ref={pageRef}>
      <AnimatePresence mode="popLayout">
        <PageHeader
          title={pageHeaderTitle}
          subtitle={form.name}
          titleComponent={
            <div className="flex items-center">
              <div className="flex sm:pl-3">
                <span className="ring-1 ring-gray-200 hover:ring-gray-300 hover:bg-gray-200/80 transition-all inline-flex items-center gap-x-1.5 rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 hover:text-gray-700">
                  <Switch isSelected={editing} onChange={handleToggleEditMode}>
                    <p className="text-sm">{translate('pages.form-details.change-switch')}</p>
                  </Switch>
                </span>
              </div>
              <ChangeIndicator
                changed={changed}
                saveCallback={handleUpdateForm}
                discardCallback={handleToggleEditMode}
                submitting={submitting}
                editing={editing}
              />
            </div>
          }
        />
        <div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
            <Title
              title={translate('pages.form-details.basics.title')}
              subtitle={translate('pages.form-details.basics.subtitle')}
              icon={<ListTodo className="size-4" />}
              complete={true}
              hideCompletion={true}
            />
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6 md:col-span-2">
              <div className="col-span-full">
                <Label htmlFor="name" className="text-right">
                  {translate('pages.form-details.basics.name.title')}
                </Label>
                <Input
                  disabled={!editing}
                  className="bg-white mt-2 w-full ml-0"
                  type={'text'}
                  id="formName"
                  placeholder={translate('pages.form-details.basics.formName.placeholder')}
                  value={formName}
                  onChange={(e) => {
                    setFormName(e.target.value)
                    setChanged(true)
                  }}
                />
              </div>
              <div className="col-span-full mt-4">
                <Label htmlFor="description" className="text-right">
                  {translate('pages.form-details.basics.description.title')}
                </Label>
                <Textarea
                  disabled={!editing}
                  className="bg-white mt-2 w-full ml-0"
                  id="formDescription"
                  placeholder={translate('pages.form-details.basics.formDescription.placeholder')}
                  value={formDescription}
                  onChange={(e) => {
                    setFormDescription(e.target.value)
                    setChanged(true)
                  }}
                />
              </div>
            </div>

            <Title
              title={translate('pages.form-details.questions.title')}
              subtitle={translate('pages.form-details.questions.subtitle')}
              icon={<MessageCircleQuestionIcon className="size-4" />}
              complete={true}
              hideCompletion={true}
            />

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6 md:col-span-2">
              <div className="col-span-full gap-y-4 flex flex-row justify-end">
                <Button
                  variant={'indigo'}
                  disabled={!editing}
                  onClick={() => {
                    // Add new FormQuestion
                    setActiveFormQuestions([
                      {
                        id: 'new' + Math.random().toString(),
                        weight: 0,
                        question: '',
                        questionType: FormQuestionEntity.questionType.TEXT,
                        required: false,
                        selectOptions: [],
                        archived: false,
                      } as any,
                      ...activeFormQuestions,
                    ])
                  }}
                >
                  {translate('pages.form-details.questions.addQuestionButton')}
                  <MessageSquarePlus className="ml-2 h-4 w-4" />
                </Button>
              </div>
              <div className="col-span-full gap-y-4">
                {activeFormQuestions.length > 0 ? (
                  <Reorder.Group
                    onReorder={() => console.log('nothing')}
                    // sort by weight
                    values={activeFormQuestions.map((fq) => fq.id)}
                  >
                    {activeFormQuestions.map((formQuestion) => (
                      <Reorder.Item
                        dragListener={false}
                        key={formQuestion.id}
                        id={formQuestion.id}
                        value={formQuestion.id}
                      >
                        <FormQuestionCard
                          formQuestion={formQuestion}
                          formQuestionUpdateCallback={formQuestionUpdateCallback}
                          weightUpdateCallback={weightUpdateCallback}
                          editing={editing}
                        />
                      </Reorder.Item>
                    ))}
                  </Reorder.Group>
                ) : (
                  <EmptyState
                    title={translate('pages.form-details.questions.emptyState.title')}
                    subtitle={translate('pages.form-details.questions.emptyState.subtitle')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </AnimatePresence>
    </div>
  )
}

interface SelectOptionWithId {
  id: string
  option: string
}

function FormQuestionCard(props: {
  formQuestion: FormQuestionEntity
  formQuestionUpdateCallback: (
    updatedFormQuestion: FormQuestionEntity,
    deleteQuestion?: boolean,
  ) => void
  weightUpdateCallback: (
    operation: 'increase' | 'decrease' | 'sink' | 'float',
    formQuestion: FormQuestionEntity,
  ) => void
  editing: boolean
}) {
  const { t: translate } = useTranslation()

  const [open, setOpen] = useState(false)

  const [formQuestionType, setFormQuestionType] = useState<FormQuestionEntity.questionType>(
    props.formQuestion.questionType,
  )

  const [formQuestionText, setFormQuestionText] = useState<string>(props.formQuestion.question)

  const [formQuestionSelectOptions, setFormQuestionSelectOptions] = useState<SelectOptionWithId[]>(
    props.formQuestion.selectOptions.map((option) => {
      return {
        id: self.crypto.randomUUID(),
        option,
      }
    }) as any,
  )

  // Close any open collapsibles when editing is disabled
  useEffect(() => {
    if (!props.editing) {
      setOpen(false)
    }
  }, [props.editing])

  const optionChangeCallback = (option: SelectOptionWithId, deleteOption?: boolean) => {
    const updatedSelectOptions = formQuestionSelectOptions
      .map((o) => {
        if (o.id === option.id) {
          if (deleteOption) {
            return null
          } else {
            return option
          }
        }
        return o
      })
      .filter((o) => o !== null) as SelectOptionWithId[]
    setFormQuestionSelectOptions(updatedSelectOptions)
    props.formQuestionUpdateCallback({
      ...props.formQuestion,
      selectOptions: updatedSelectOptions.map((o) => o.option) as any,
    })
  }

  useEffect(() => {
    if (formQuestionType && formQuestionText) {
      props.formQuestionUpdateCallback({
        ...props.formQuestion,
        question: formQuestionText,
        questionType: formQuestionType,
      })
    }
  }, [formQuestionType, formQuestionText])

  const visibleSelectOptions =
    formQuestionType === FormQuestionEntity.questionType.SELECT_CHECKBOX ||
    formQuestionType === FormQuestionEntity.questionType.SELECT_RADIO

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-4 space-y-4 bg-white p-5 ring-1 ring-gray-200 rounded-lg col-span-full"
      >
        <div className="flex justify-between bg-gray-50/50 p-1 shadow-inner rounded-lg mb-4">
          <div className="flex items-center text-gray-600">
            <Button
              variant="ghost"
              size="sm"
              disabled={!props.editing}
              onClick={() => {
                props.weightUpdateCallback('decrease', props.formQuestion)
              }}
            >
              <ChevronUp className="size-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              disabled={!props.editing}
              onClick={() => {
                props.weightUpdateCallback('increase', props.formQuestion)
              }}
            >
              <ChevronDown className="size-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              disabled={!props.editing}
              onClick={() => {
                props.weightUpdateCallback('float', props.formQuestion)
              }}
            >
              <ArrowUpToLine className="size-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              disabled={!props.editing}
              onClick={() => {
                props.weightUpdateCallback('sink', props.formQuestion)
              }}
            >
              <ArrowDownToLine className="size-4" />
            </Button>
          </div>
          <Button
            variant="ghost"
            size="sm"
            disabled={!props.editing}
            onClick={() => {
              props.formQuestionUpdateCallback(props.formQuestion, true)
            }}
          >
            <X className="size-4" />
          </Button>
        </div>
        <div className="flex flex-col mb-6">
          <p className="font-medium text-gray-800 text-sm mb-1">Frage: </p>
          <Input
            disabled={!props.editing}
            className="bg-white mt-2 w-full ml-0"
            type={'text'}
            id="questionText"
            placeholder={translate('pages.form-details.questions.questionText.placeholder')}
            value={formQuestionText}
            onChange={(e) => {
              setFormQuestionText(e.target.value)
            }}
          />
        </div>

        <Collapsible open={open} onOpenChange={setOpen}>
          <div className="flex items-center">
            <div className="flex text-gray-800 text-sm font-medium mr-1">
              {translate('pages.form-details.questions.questionType.title')}
              <Badge className="ml-2" variant={'outline'}>
                {translate('pages.form-details.questions.questionType.enum.' + formQuestionType)}
              </Badge>
            </div>

            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm" disabled={!props.editing}>
                {!open ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent className="space-y-2 mt-2">
            <RadioGroup
              disabled={!props.editing}
              id={props.formQuestion.id}
              onValueChange={(value) => {
                setFormQuestionType(value as FormQuestionEntity.questionType)
              }}
              value={formQuestionType}
              defaultValue="card"
              className="grid grid-cols-1 sm:grid-cols-3 sm:grid-rows-2 gap-3 p-3 bg-gray-50 rounded-lg shadow-inner ring-1 ring-gray-100"
            >
              <div>
                <RadioGroupItem
                  value="TEXT"
                  id={'TEXT' + props.formQuestion.id}
                  className="peer sr-only"
                />
                <Label
                  htmlFor={'TEXT' + props.formQuestion.id}
                  className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                >
                  <Pencil className="size-4 mb-3" />
                  {translate(
                    'pages.form-details.questions.questionType.enum.' +
                      FormQuestionEntity.questionType.TEXT,
                  )}
                </Label>
              </div>
              <div>
                <RadioGroupItem
                  value="TEXTAREA"
                  id={'TEXTAREA' + props.formQuestion.id}
                  className="peer sr-only"
                />
                <Label
                  htmlFor={'TEXTAREA' + props.formQuestion.id}
                  className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                >
                  <NotebookPen className="size-4 mb-3" />
                  {translate(
                    'pages.form-details.questions.questionType.enum.' +
                      FormQuestionEntity.questionType.TEXTAREA,
                  )}
                </Label>
              </div>
              <div>
                <RadioGroupItem
                  value="DATE"
                  id={'DATE' + props.formQuestion.id}
                  className="peer sr-only"
                />
                <Label
                  htmlFor={'DATE' + props.formQuestion.id}
                  className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                >
                  <CalendarDays className="size-4 mb-3" />
                  {translate(
                    'pages.form-details.questions.questionType.enum.' +
                      FormQuestionEntity.questionType.DATE,
                  )}
                </Label>
              </div>
              <div>
                <RadioGroupItem
                  value="SELECT_CHECKBOX"
                  id={'SELECT_CHECKBOX' + props.formQuestion.id}
                  className="peer sr-only"
                />
                <Label
                  htmlFor={'SELECT_CHECKBOX' + props.formQuestion.id}
                  className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                >
                  <ClipboardList className="size-4 mb-3" />
                  {translate(
                    'pages.form-details.questions.questionType.enum.' +
                      FormQuestionEntity.questionType.SELECT_CHECKBOX,
                  )}
                </Label>
              </div>
              <div>
                <RadioGroupItem
                  value="SELECT_RADIO"
                  id={'SELECT_RADIO' + props.formQuestion.id}
                  className="peer sr-only"
                />
                <Label
                  htmlFor={'SELECT_RADIO' + props.formQuestion.id}
                  className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                >
                  <ClipboardCheck className="size-4 mb-3" />
                  {translate(
                    'pages.form-details.questions.questionType.enum.' +
                      FormQuestionEntity.questionType.SELECT_RADIO,
                  )}
                </Label>
              </div>
            </RadioGroup>
            {visibleSelectOptions && (
              <div className="pt-3 gap-y-2 flex flex-col">
                <div>
                  <Button
                    variant="secondary"
                    disabled={!props.editing}
                    onClick={() => {
                      setFormQuestionSelectOptions([
                        ...formQuestionSelectOptions,
                        {
                          id: self.crypto.randomUUID(),
                          option: '',
                        },
                      ])
                    }}
                  >
                    {translate('pages.form-details.questions.questionSelectOptions.addButton')}
                    <MessageSquareText className="ml-2 h-4 w-4" />
                  </Button>
                </div>
                <ul className="list-decimal pl-6 text-gray-600 text-sm">
                  {formQuestionSelectOptions.length > 0 &&
                    formQuestionSelectOptions.map((option) => (
                      <li className="pl-2">
                        <FormQuestionSelectOption
                          option={option}
                          optionChangeCallback={optionChangeCallback}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>

        <div className="flex items-center">
          <div className="items-top flex space-x-2 sm:max-w-md">
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor={'required' + props.formQuestion.id}
                className="text-sm font-medium text-gray-800 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {translate('pages.form-details.questions.required.title')}
              </label>
              <p className="text-sm text-muted-foreground">
                {translate('pages.form-details.questions.required.subtitle')}
              </p>
            </div>
            <Checkbox
              id={'required' + props.formQuestion.id}
              disabled={!props.editing}
              checked={props.formQuestion.required}
              onCheckedChange={(e) => {
                const checked = e.valueOf()
                if (typeof checked === 'boolean') {
                  props.formQuestionUpdateCallback({
                    ...props.formQuestion,
                    required: checked,
                  })
                }
              }}
            />
          </div>
        </div>
      </motion.div>
    </>
  )
}

function FormQuestionSelectOption(props: {
  option: SelectOptionWithId
  optionChangeCallback: (option: SelectOptionWithId, deleteOption?: boolean) => void
}) {
  const [option, setOption] = useState(props.option.option)
  const { t: translate } = useTranslation()

  return (
    <div className="flex items-center mt-4">
      <Input
        className="bg-white w-full text-gray-800"
        type={'text'}
        id={props.option.id}
        placeholder={translate('pages.form-details.questions.questionSelectOptions.placeholder')}
        value={option}
        onChange={(e) => {
          setOption(e.target.value)
          props.optionChangeCallback({
            id: props.option.id,
            option: e.target.value,
          })
        }}
      />
      <Button
        variant={'ghost'}
        size={'sm'}
        onClick={() => props.optionChangeCallback(props.option, true)}
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  )
}
