import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { CourseGroupEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

interface CourseGroupFilters {
  instructorId?: string
  archived?: boolean
}

export default function useGetCourseGroups(filters?: CourseGroupFilters): {
  courseGroups: CourseGroupEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: courseGroups,
    isLoading,
    refetch,
    isError,
    isRefetching,
  } = useQuery(getCourseGroupsQueryKey(filters), async () => {
    return qoursesApi.courseGroup.courseGroupControllerGetCourseGroups(
      filters?.instructorId,
      filters?.archived,
    )
  })

  const _isEmpty = isEmpty(courseGroups)

  if (courseGroups === undefined) {
    return { courseGroups: [], isLoading, isEmpty: _isEmpty, isError, refetch, isRefetching }
  }

  return { courseGroups, isLoading, isEmpty: _isEmpty, isError, refetch, isRefetching }
}

export const getCourseGroupsQueryKey = (filters?: CourseGroupFilters) => [
  'courses',
  'courseGroups',
  filters,
]
